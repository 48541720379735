import * as firebase from 'firebase';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Listing } from './listings.service';
import { Register } from './register.service';
import { Observable, of, observable } from 'rxjs';
import { Timestamp } from '@firebase/firestore-types';
import { AngularFireStorage } from '@angular/fire/storage';
import { ToggleSideMenuService } from '../services/toggle-side-menu.service';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { ApiService } from '../services/api.service';

export class Ad_Submission {
  AdSubmissionID: string;
  IssueID: string;
  userID: string;
  adSizeName: string;
  adDesc: string;
  adSize: string;
  isSubmitted: boolean;
  SpecialInstructions: string;
  AdditionalInfo: string;
  toggleStep1Panel: boolean;
  toggleStep2Panel: boolean;
  toggleStep3Panel: boolean;
  toggleStep4Panel: boolean;
  requiresChanges: boolean;
  waiting: boolean;
  userAdSize: number;
  submittedAt: Timestamp;
}

export class AdSizeDetails {
  FileName: string;
  PictureURL: string;
  Size: string;
  adDesc: string;
}

export class Ad_Proof {
  ID: string;
  AdProof: string;
  UID: string;
  requiresChanges: boolean;
  //changes: string[] = [];
  proofChange: string = '';
  disabled: boolean;
  issueNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdSubmissionService {
  private submissionCollection: AngularFirestoreCollection<Ad_Submission>;
  private adSubmission: Observable<Ad_Submission[]>;

  private submissionDetails: Ad_Submission = new Ad_Submission();

  private Listings: Listing[] = [];

  private allAdSizes: AdSizeDetails[] = [];

  private allProofs: string[] = [];

  private allUserAds: Ad_Submission[] = [];

  hasTools: boolean;

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private toggleSideMenuService: ToggleSideMenuService,
    private api: ApiService
  ) {
    this.submissionCollection = db.collection<Ad_Submission>('Listings');

    this.adSubmission = this.submissionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  get submissionInformation() {
    return of(this.submissionDetails);
  }

  getAdProofs(UID: string) {
    return (
      this.db
        //.collection('/Ad-Submissions')
        //.doc(submissionID)
        .collection('/Ad-Proof', ref =>
          ref.where('UID', '==', UID).orderBy('Timestamp')
        )
        .snapshotChanges()
    );
  }

  get Proofs() {
    return of(this.allProofs);
  }

  /* 
    Query for the  current Ad Submission document tied to the user and return it.
  */
  getCurrentSubmission(userID: string) {
    return this.db
      .collection('/Ad-Submissions', ref => ref.where('userID', '==', userID))
      .snapshotChanges();
  }

  /* 
    Add a new document to the Ad Submission collection then add it's ID to the user it is attached to.
  */
  createSubmission(newSubmission: Ad_Submission, docID: string) {
    return this.db
      .collection('/Ad-Submissions')
      .doc(docID)
      .set({
        AdSubmissionID: docID,
        IssueID: newSubmission.IssueID,
        adSizeName: newSubmission.adSizeName,
        userID: newSubmission.userID,
        adSize: newSubmission.adSize
      })
      .then(() => {
        this.db
          .collection('/Users')
          .doc(newSubmission.userID)
          .update({ currentSubmissionID: docID });
      });
  }

  updateSubmissionInfo(submissionInfo: Ad_Submission) {
    this.db
      .collection('/Ad-Submissions')
      .doc(submissionInfo.AdSubmissionID)
      .update(submissionInfo);
  }

  updateAdProofs(adProof: Ad_Proof) {
    // adProofs.forEach(adProof => {
    //   this.db
    //     //.collection('/Ad-Submissions')
    //     //.doc(submissionID)
    //     .collection('/Ad-Proof')
    //     .doc(adProof.ID)
    //     .update({ ...adProof });
    // });
    this.db
      .collection('/Ad-Proof')
      .doc(adProof.ID)
      .update({
        ...adProof
        //changes: firebase.firestore.FieldValue.arrayUnion(adProof.proofChange)
      });
    this.api.userProof(adProof.UID, adProof.requiresChanges);
  }

  checkCurrentAdSize(
    currentSubmission: Ad_Submission,
    listings: Array<Listing>
  ) {
    //console.log(currentSubmission, listings);

    currentSubmission.userAdSize = listings.length;

    listings.forEach(listing => {
      if (listing.assets.length > 1) {
        currentSubmission.userAdSize += listing.assets.length - 1;
      }
    });

    this.updateSubmissionInfo(currentSubmission);
  }

  modifyCurrentAdSize(currentSubmission: Ad_Submission, correction: number) {
    currentSubmission.userAdSize += correction;

    this.updateSubmissionInfo(currentSubmission);
  }

  submitAd(submissionInfo: Ad_Submission) {
    this.toggleSideMenuService.currentlyAdmin.subscribe(
      admin => (this.hasTools = admin)
    );

    if (!this.hasTools) {
      console.log('Timestamp here');
      submissionInfo.submittedAt = firebase.firestore.FieldValue.serverTimestamp() as Timestamp;
    }

    submissionInfo.isSubmitted = true;
    this.db
      .collection('/Ad-Submissions')
      .doc(submissionInfo.AdSubmissionID)
      .update(submissionInfo);
  }

  toggleAdStatusByID(submissionID: string, toggle: boolean) {
    this.db
      .collection('/Ad-Submissions')
      .doc(submissionID)
      .update({
        isSubmitted: toggle
      });
  }

  getAllSubmissions(UID: string) {
    return this.db
      .collection('/Ad-Submissions', ref => ref.where('userID', '==', UID))
      .snapshotChanges();
  }

  getListings(adSubmissionID: string) {
    this.db
      .collection('/Ad-Submissions')
      .doc(adSubmissionID)
      .collection('/Ad-Listings')
      .snapshotChanges()
      .subscribe(val => {
        this.Listings.length = 0;
        val.forEach(Listing => {
          this.Listings.push(Listing.payload.doc.data() as Listing);
        });
      });
  }

  get getSubmissionListings() {
    return of(this.Listings);
  }

  getAdSizePhotos() {
    this.db
      .collection('/Ad-Sizes', ref => ref.orderBy('Size'))
      .snapshotChanges()
      .subscribe(adSizes => {
        this.allAdSizes.length = 0;
        adSizes.forEach(adSize => {
          this.allAdSizes.push(adSize.payload.doc.data() as AdSizeDetails);
        });
      });
  }

  get adSizeDetails() {
    return of(this.allAdSizes);
  }

  getUsersSubmissions(users: Register[]) {
    this.db
      .collection('/Ad-Submissions')
      .snapshotChanges()
      .subscribe(docs => {
        docs.forEach(doc => {
          this.allUserAds.push(doc.payload.doc.data() as Ad_Submission);
        });
      });
  }

  get userAds() {
    return of(this.allUserAds);
  }
}
