import { Observable } from 'rxjs';
import { Listing, ListingService } from '../services/listings.service';
import { trigger, style, transition, animate } from '@angular/animations';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';

import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-listing-popup',
  templateUrl: './listing-popup.component.html',
  styleUrls: ['./listing-popup.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100),
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' })),
      ]),
    ]),
  ],
})
export class ListingPopupComponent implements OnInit {
  @Input() closable = true;
  @Input() visible: boolean;
  @Output() listingInfoEvent = new EventEmitter<Listing>();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('mls', { static: false })
  mlsRB: MatRadioButton;

  @ViewChild('newListing', { static: false })
  newListingRB: MatRadioButton;

  @ViewChild('comingSoon', { static: false })
  comingSoonRB: MatRadioButton;

  @ViewChild('newPrice', { static: false })
  newPriceRB: MatRadioButton;

  @ViewChild('sold', { static: false })
  soldRB: MatRadioButton;

  @ViewChild('noBanner', { static: false })
  noBannerRB: MatRadioButton;

  @ViewChild('other', { static: false })
  otherRB: MatRadioButton;

  @ViewChild('Repeat', { static: false })
  repeatRB: MatRadioButton;

  @ViewChild('EmailLater', { static: false })
  emailLaterRB: MatRadioButton;

  @ViewChild('TakeForMe', { static: false })
  takeForMeRB: MatRadioButton;

  @ViewChild('Upload', { static: false })
  uploadRB: MatRadioButton;

  @ViewChild('Complete', { static: false })
  completeRB: MatRadioButton;

  @ViewChild('description', { static: false })
  desc: ElementRef;

  bannerMsg: string;
  picOptionMsg: string;

  listing: Listing = new Listing();

  fileCount: number;
  fileExists: boolean;

  assets: string[] = [];

  allFiles: File[];

  isHovering: boolean;

  isMLSSelected: boolean;

  listingIssue: string;

  charLength: string;

  photoSelected: boolean = false;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;

  arStatus: string = 'Enable';
  arEnabled: string = 'xplor-logo-img-checked';
  arDisabled: string = 'xplor-logo-img-unchecked';
  imageClass: string = this.arDisabled;

  constructor(
    private storage: AngularFireStorage,
    private listingService: ListingService
  ) {}

  ngOnInit() {
    this.listing.assets = [];
    this.listingIssue = 'Invalid';

    //this.listing.MLS = false;
    this.listing.hasAR = false;

    this.fileExists = false;
  }

  onSelectionChange() {
    this.isMLSSelected = this.mlsRB.checked === true ? true : false;
    this.listing.MLS = this.mlsRB.checked === true ? 'MLS' : 'Exclusive';
    if (this.listing.MLS === 'Exclusive') {
      this.listing.MLSNum = '';
    }
  }

  bannerSelectChange() {
    if (this.newListingRB.checked) {
      this.bannerMsg = 'New Listing';
    }
    if (this.comingSoonRB.checked) {
      this.bannerMsg = 'Coming Soon';
    }
    if (this.newPriceRB.checked) {
      this.bannerMsg = 'New Price';
    }
    if (this.soldRB.checked) {
      this.bannerMsg = 'Sold';
    }
    if (this.noBannerRB.checked) {
      this.bannerMsg = 'No Banner';
    }
    if (this.otherRB.checked) {
      this.bannerMsg = 'Other';
    }
    this.listing.Banner = this.bannerMsg;
  }

  picOptionSelectChange() {
    this.photoSelected = true;
    if (this.repeatRB.checked) {
      this.picOptionMsg = 'Repeat from last issue';
    }
    if (this.emailLaterRB.checked) {
      this.picOptionMsg = 'I will email';
    }
    if (this.takeForMeRB.checked) {
      this.picOptionMsg = 'Take photo for me';
    }
    if (this.uploadRB.checked) {
      this.picOptionMsg = 'Upload';
    }
    if (this.completeRB.checked) {
      this.picOptionMsg = 'No photo required';
    }
    this.listing.PhotoOption = this.picOptionMsg;
  }

  addListing() {
    // console.log(this.listing);
    this.verifyListings();
    //if (this.listing.Price == undefined || this.listing.Price == '') {
    //  this.listing.Price = '0';
    //}
    this.listing.Price = this.formatPrice(this.listing.Price);
    if (this.listingIssue != 'valid') {
      alert(this.listingIssue);
    } else {
      this.assets.forEach((asset) => {
        this.listing.assets.push(asset);
      });
      this.listingInfoEvent.emit(this.listing);
      this.close();
    }
  }

  formatPrice(price) {
    //Remove letters and special chars
    if (price != undefined || price != null) {
      let tmpPrice = price.toString();
      tmpPrice = tmpPrice.replace(/[\s\D]/gi, '');
      var num = tmpPrice.replace(/,/gi, '');
      tmpPrice = num.split(/(?=(?:\d{3})+$)/).join(',');
      price = tmpPrice;
    } else {
      price = '0';
    }

    return price;
    //this.updateListing();
  }

  testAddListing() {
    this.verifyListings();
    if (this.listingIssue != 'valid') {
      alert(this.listingIssue);
    }
    console.log(this.listing);
  }

  toggleAR() {
    this.listing.hasAR = !this.listing.hasAR;
    this.imageClass =
      this.imageClass === this.arEnabled ? this.arDisabled : this.arEnabled;
    this.arStatus = this.listing.hasAR === true ? 'Disable' : 'Enable';
    console.log(
      'IMAGE CLASS : ' + this.imageClass + ', HAS AR : ' + this.listing.hasAR
    );
  }

  calcLength() {
    this.charLength = this.desc.nativeElement.value.length.toString();
  }

  onDrop(files: File[], listingOrder: number) {
    this.allFiles = files;

    for (var i = 0; i < this.allFiles.length; i++) {
      if (this.allFiles[i].type.split('/')[0] !== 'image') {
        console.error('Unsupported file type :( ');
        alert('Please only upload JPEG files!');
        return;
      }
    }

    if (this.fileCount !== undefined) {
      this.fileCount += files.length;
    } else {
      this.fileCount = files.length;
    }
    if (this.fileCount > 0) {
      this.fileExists = true;
    }
    //Rework this later
    for (var i = 0; i < files.length; i++) {
      const path = `listing/${Date.now()}_${files[i].name}`;
      const ref = this.storage.ref(path);

      this.task = this.storage.upload(path, files[i]);
      this.percentage = this.task.percentageChanges();
      this.snapshot = this.task.snapshotChanges();
      this.task.then(() => {
        //this.assets.push(fileInfo.downloadURL);
        ref.getDownloadURL().subscribe((downloadURL) => {
          this.assets.push(downloadURL);
        });
      });
    }
  }

  //Close the dialog box
  close() {
    this.picOptionMsg = '';
    this.photoSelected = false;
    this.visible = false;
    this.fileExists = false;
    this.isMLSSelected = false;
    this.imageClass = this.arDisabled;
    this.visibleChange.emit(this.visible);
    this.clearObject();
  }

  clearObject() {
    this.listing = {} as Listing;
    this.listing.assets = [];
    this.assets = [];
    this.fileCount = 0;
    this.listing.hasAR = false;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  verifyListings() {
    // if (this.listing.MLS != undefined) {
    //   if (this.listing.Address != undefined) {
    //     var cityRegex = /^[a-zA-Z\-', ]+$/;
    //     if (this.listing.City.match(cityRegex)) {
    //       this.listingIssue = 'valid';
    //     } else {
    //       this.listingIssue = 'City is Invalid.';
    //     }
    //   } else {
    //     this.listingIssue = 'Address is Invalid.';
    //   }
    // } else {
    //   this.listingIssue =
    //     'MLS or Exclusive not selected (MLS number not required)';
    // }
    this.listingIssue = '';
    let cityRegex = /^[a-zA-Z\-', ]+$/;
    if (this.listing.MLS == undefined) {
      this.listingIssue +=
        '\nMLS or Exclusive not selected (MLS number not required)';
    }
    if (this.listing.City == undefined || this.listing.City === '') {
      this.listingIssue += '\nCity Field is Required';
    } else {
      if (!this.listing.City.match(cityRegex)) {
        this.listingIssue += '\nCity is Invalid.';
        console.log(this.listing.MLS);
      }
    }
    if (this.listing.Address == undefined || this.listing.Address === '') {
      this.listingIssue += '\nAddress Field is Required';
    }
    if (this.listingIssue === '') {
      this.listingIssue = 'valid';
    }
    if (
      this.listing.PhotoOption === 'I will email' &&
      this.listing.numOfPhoto == undefined
    ) {
      this.listingIssue = 'Please specify number of photos you will email.';
    }
    if (!this.photoSelected) {
      this.listingIssue = 'Please select photo option';
    }
  }
}
