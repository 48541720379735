import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalsService, UserType } from '../services/globals.service';
import { RegisterService, Register } from '../services/register.service';

export class userCredentials {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user: userCredentials = new userCredentials();
  isDivVisible;

  hideImg: boolean;

  constructor(
    public fAuth: AngularFireAuth,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private globalsService: GlobalsService,
    private profile: RegisterService
  ) {}

  ngOnInit() {
    /*
    if (JSON.parse(localStorage.getItem('UID')) != undefined) {
      this.router.navigateByUrl('/submitAD');
    }*/

    this.isDivVisible = false;

    if (!this.deviceService.isMobile()) {
      this.hideImg = true;
    }

    this.fAuth.auth.onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        this.router.navigateByUrl('/submitAD');
      } else {
        // No user is signed in.
      }
    });
  }

  async login() {
    try {
      var r = await this.fAuth.auth.signInWithEmailAndPassword(
        this.user.email,
        this.user.password
      );

      if (r) {
        this.router.navigateByUrl('/submitAD');
      }
    } catch (err) {
      this.isDivVisible = true;
      console.error(err);
    }
  }
}
