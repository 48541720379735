import { Router } from '@angular/router';
import { Listing } from '../services/listings.service';
import { MatSort, Sort } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { GlobalsService, UserType } from '../services/globals.service';
import { Register, RegisterService } from '../services/register.service';
import { BookInfo, BookInfoService } from '../services/book-info.service';
import { NavRoutes, NavRoutesService } from '../services/nav-routes.service';
import { ListingService } from '../services/listings.service';
import { _MatTabHeaderMixinBase } from '@angular/material/tabs/typings/tab-header';
import {
  Ad_Proof,
  Ad_Submission,
  AdSubmissionService,
} from '../services/ad-submission.service';
import {
  Office,
  OrganizationsService,
} from '../services/organizations.service';
import { ApiService } from '../services/api.service';

export class Table_Info {
  user: Register;
  submitted: boolean;
  company: string;
  adSize: string;
  waiting: boolean;
  submissionInfo: Ad_Submission;
}

@Component({
  selector: 'app-ad-list',
  templateUrl: './ad-list.component.html',
  styleUrls: ['./ad-list.component.scss'],
})
export class AdListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  bookInfo: BookInfo = new BookInfo();
  submissions: Ad_Submission[] = [];
  users: Register[] = [];

  hasPicture: boolean;
  userName: string;

  proofPic: FileList;
  userID: string;

  NavigationRoutes: NavRoutes[];
  userOffice: Office = new Office();

  loggedInName: string;
  editProfile: boolean;

  user: Register = new Register();
  userType: UserType = new UserType();

  //allUserAds: Ad_Submission[] = [];

  tableInfo: Table_Info[] = [];

  userOfficeList: Office[] = [];

  adProofs: Ad_Proof[] = [];

  issue: BookInfo = new BookInfo();
  adDeadline: Date = new Date();

  releaseAllAds: boolean;
  showBookInfo: boolean;

  constructor(
    private router: Router,
    private db: AngularFirestore,
    public fAuth: AngularFireAuth,
    private profile: RegisterService,
    private globalsService: GlobalsService,
    private bookInfoService: BookInfoService,
    private registerService: RegisterService,
    private NavigationService: NavRoutesService,
    private adSubmissionService: AdSubmissionService,
    private organizationsService: OrganizationsService,
    private storage: AngularFireStorage,
    private api: ApiService,
    private listingService: ListingService
  ) {
    this.bookInfoService.releaseAds.subscribe((value) => {
      if (value) this.assembleTableInfo();
    });
  }

  ngAfterViewInit() {
    this.addProofStatusClass();
  }

  ngOnInit() {
    this.releaseAllAds = false;
    this.showBookInfo = false;
    //this.getCurrentSubmissions();
    this.getUserList();
    this.bookInfoService.getCurrentIssue().subscribe((val) => {
      this.bookInfo = val[0].payload.doc.data() as BookInfo;

      this.users.forEach((user) => {
        if (user.officeID == undefined) {
          this.userOfficeList.push({
            ID: '',
            Name: '',
            Address: '',
            PhoneNumber: '',
            Website: '',
            Email: '',
            Logo: '',
          });
        } else {
          this.db
            .collection('/Offices')
            .doc(user.officeID)
            .snapshotChanges()
            .subscribe((office) => {
              this.userOfficeList.push(office.payload.data() as Office);
            });
        }
      });

      this.getAgentInformation();
      this.assembleLoggedInName();

      this.NavigationService.Navs.subscribe((Navs) => {
        this.NavigationRoutes = Navs;

        //this.adSubmissionService.userAds.subscribe(Ads => {
        //  this.allUserAds = Ads;
        //});
      });

      this.assembleTableInfo();
    });
  }

  //testing
  addProofStatusClass() {
    this.getUserList();
    //idk why this makes it work but it is needed
    this.adSubmissionService.getAdProofs('1').subscribe((val) => {
      this.users.forEach((user) => {
        //---------------------- testing --------------------------------//
        this.adSubmissionService.getAdProofs(user.UID).subscribe((proofs) => {
          this.adProofs = [];
          proofs.forEach((proof) => {
            var tmpProof = proof.payload.doc.data() as Ad_Proof;
            this.adProofs.push(tmpProof);
          });
          let element = document.getElementById('proofStatus-' + user.UID);
          if (this.adProofs.length > 0) {
            //check most recent proof review
            if (
              this.adProofs[this.adProofs.length - 1].requiresChanges === true
            ) {
              //client has submitted feedback and requires changes, color icon red
              element.classList.add('proof-status-red');
            } else if (
              this.adProofs[this.adProofs.length - 1].requiresChanges === false
            ) {
              //client has submitted feedback and does not require changes, color icon green
              element.classList.add('proof-status-green');
            } else {
              //client has not submitted feedback, leave icon black
            }
          }
        });
        //---------------------- testing --------------------------------//
      });
    });
  }

  defaultPhotoOption() {
    this.listingService.photoOptionDefault();
  }

  releaseAllUserADs() {
    if (confirm('Are you sure you want to release ads?')) {
      this.db
        .collection('/Ad-Submissions')
        .snapshotChanges()
        .subscribe((AdSubmissions) => {
          AdSubmissions.forEach((AdSubmission) => {
            let tmpSubmission =
              AdSubmission.payload.doc.data() as Ad_Submission;
            let ID = tmpSubmission.AdSubmissionID;
            this.db
              .collection('/Ad-Submissions')
              .doc(ID)
              .update({ isSubmitted: false });
          });
        });
      // this.listingService.photoOptionDefault();
      this.assembleTableInfo();
    } else {
      console.log('Cancelled releasing ads.');
    }
    this.showBookInfo = true;
  }

  setBookInfo() {
    this.bookInfoService.addIssue(this.issue, this.adDeadline);
    this.showBookInfo = false;
  }

  setPhotoOption() {
    this.db
      .collection('/Listings')
      .snapshotChanges()
      .subscribe((allListings) => {
        allListings.forEach((listing) => {
          var list = listing.payload.doc.data() as Listing;
          list.PhotoOption = 'Repeat from last issue';
          this.updateListing(list);
        });
      });
  }

  updateListing(listing: Listing) {
    this.db
      .collection('Listings')
      .doc(listing.ID)
      .update({ ...listing });
  }

  assembleLoggedInName() {
    this.loggedInName = this.user.firstName + this.user.lastName;
  }

  toggleProfilePrompt() {
    this.editProfile = true;
  }

  getAgentInformation() {
    var userDetails = JSON.parse(localStorage.getItem('userLogin'));
    this.profile
      .getRegister(JSON.parse(localStorage.getItem('UID')))
      .subscribe((val) => {
        this.user = val as Register;
        this.user.email = userDetails.email;
        this.organizationsService
          .getOfficeByID(val.officeID)
          .subscribe((office) => {
            this.userOffice = office.payload.data() as Office;

            this.globalsService
              .getUserTypeDetails(this.user.userType)
              .subscribe((val) => {
                this.userType = val.payload.data() as UserType;

                if (this.userType.HasTools == false) this.goToAdSubmission();

                this.checkForProfPicture();
              });
          });
      });
  }

  //https://github.com/HaithemMosbahi/ngx-avatar
  checkForProfPicture() {
    if (
      this.user.picture == '' ||
      this.user.picture == null ||
      this.user.picture == undefined
    ) {
      this.hasPicture = false;

      this.userName = this.user.firstName + ' ' + this.user.lastName;
    } else {
      this.hasPicture = true;
    }
  }

  getUserList() {
    this.registerService.getAgents();
    this.registerService.userList.subscribe((val) => {
      this.users = val;
    });
  }

  getCurrentSubmissions() {
    this.adSubmissionService.getUsersSubmissions(this.users);
  }

  logout() {
    return this.fAuth.auth.signOut().then(() => {
      this.router.navigateByUrl('/login');
    });
  }

  goToAdSubmission() {
    this.router.navigateByUrl('/submitAD');
  }

  sortData(sort: Sort) {
    const data = this.tableInfo.slice();
    if (!sort.active || sort.direction === '') {
      this.tableInfo = data;
      return;
    }

    this.tableInfo = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'submitted':
          return compare(a.submitted.toString(), b.submitted.toString(), isAsc);
        case 'lastName':
          return compare(a.user.lastName, b.user.lastName, isAsc);
        case 'firstName':
          return compare(a.user.firstName, b.user.firstName, isAsc);
        case 'adSize':
          return compare(a.adSize, b.adSize, isAsc);
        case 'waiting':
          return compare(a.waiting.toString(), b.waiting.toString(), isAsc);
        case 'submittedOn':
          var t1, t2;

          if (!a.submissionInfo.submittedAt) {
            t1 = 0;
          } else {
            t1 = a.submissionInfo.submittedAt.seconds;
          }

          if (!b.submissionInfo.submittedAt) {
            t2 = 0;
          } else {
            t2 = b.submissionInfo.submittedAt.seconds;
          }

          return compare(t1, t2, isAsc);
        default:
          return 0;
      }
    });
  }

  toggleSubmitted(user: Register, submitted: boolean, index: number) {
    this.db
      .collection('/Users')
      .doc(user.UID)
      .snapshotChanges()
      .subscribe((profile) => {
        var tmpProfile = profile.payload.data() as Register;
        if (submitted) {
          this.adSubmissionService.toggleAdStatusByID(
            tmpProfile.currentSubmissionID,
            false
          );
          this.tableInfo[index].submitted = !this.tableInfo[index].submitted;
        } else {
          this.adSubmissionService.toggleAdStatusByID(
            tmpProfile.currentSubmissionID,
            true
          );
          this.tableInfo[index].submitted = !this.tableInfo[index].submitted;
        }
      });
  }

  assembleTableInfo() {
    this.tableInfo = [];

    var i = 0;
    this.users.forEach((user) => {
      this.db
        .collection('/Ad-Submissions')
        .doc(user.currentSubmissionID)
        .snapshotChanges()
        .subscribe((Ad) => {
          var tmpAd = Ad.payload.data() as Ad_Submission;

          if (tmpAd.waiting == undefined) tmpAd.waiting = false;

          this.tableInfo.push({
            user: user,
            submitted: tmpAd.isSubmitted,
            company: this.userOfficeList[i].Name,
            adSize: tmpAd.adDesc,
            waiting: tmpAd.waiting,
            submissionInfo: tmpAd,
          });
          i++;
          const sortState: Sort = { active: 'submitted', direction: 'desc' };
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState);
        });
    });
  }

  //upload proofs
  proofPhoto(event: FileList) {
    this.proofPic = null;
    this.proofPic = event;
    this.addUserProof();
  }

  //redundant can remove eventually
  addUserProof() {
    if (
      confirm(
        'Upload ' +
          this.proofPic.item(0).name +
          ' to ' +
          this.user.firstName +
          ' ' +
          this.user.lastName
      )
    ) {
      this.uploadFile(this.proofPic, 'user_proof');
      this.api.adminProof(this.user.UID);
    } else {
    }
  }

  setUserID(userID: string) {
    this.userID = userID;
  }

  uploadFile(event: FileList, folder: string) {
    // The File object
    const file = event.item(0);
    // if (file.type.split('/')[0] !== 'image') {
    //   console.error('Unsupported file type :( ');
    //   return;
    // }

    const path = `${folder}/${new Date().getTime()}_${file.name}`; // The storage path
    const fileRef = this.storage.ref(path); //File reference

    // The main task
    this.storage.upload(path, file).then(() => {
      fileRef.getDownloadURL().subscribe((val) => {
        this.registerService.addUserProof(this.userID, val);
      });
    });
  }

  openProof(UID: string) {
    // this.adSubmissionService.getAdProofs(UID).subscribe(proofs => {
    //   this.adProofs = [];
    //   proofs.forEach(proof => {
    //     var tmpProof = proof.payload.doc.data() as Ad_Proof;
    //     this.adProofs.push(tmpProof);
    //   });
    // });
    // var name = 'alex';
    // var myWindow = window.open('', 'MsgWindow', 'width=200,height=100');
    // myWindow.document.write('<p>${name}</p>');
  }

  init(uid: string) {
    console.log(uid);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
