import { Directive, EventEmitter, Output, HostListener } from '@angular/core';
import { ListingService } from './services/listings.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Directive({
  selector: '[dropzone]'
})
export class DropzoneDirective {
  constructor(
    private fAuth: AngularFireAuth,
    private listingService: ListingService
  ) {}

  @Output() dropped = new EventEmitter<FileList>();
  @Output() hovered = new EventEmitter<boolean>();

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();
    this.dropped.emit($event.dataTransfer.files);
    this.hovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();
    this.hovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    $event.preventDefault();
    this.hovered.emit(false);
  }
}
