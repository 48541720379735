import { Component, OnInit } from '@angular/core';
import { Register } from '../services/register.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Listing, ListingService } from '../services/listings.service';
import {
  Ad_Submission,
  AdSubmissionService
} from '../services/ad-submission.service';

@Component({
  selector: 'app-export-text',
  templateUrl: './export-text.component.html',
  styleUrls: ['./export-text.component.scss']
})
export class ExportTextComponent implements OnInit {
  listings: Listing[] = [];
  submission: Ad_Submission = new Ad_Submission();
  user: Register = new Register();

  constructor(
    private router: Router,
    private listingService: ListingService,
    private activatedRoute: ActivatedRoute,
    private adSubmissionService: AdSubmissionService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.user.UID = params['UID'];
      this.user.firstName = params['firstName'];
      this.user.lastName = params['lastName'];

      this.getAdSubmissions();
      this.getAdListings();
    });
  }

  getAdSubmissions() {
    this.adSubmissionService.getAllSubmissions(this.user.UID).subscribe(val => {
      this.submission = val[0].payload.doc.data() as Ad_Submission;
    });
  }

  getAdListings() {
    this.listingService.getAdListings(this.user.UID);
    this.listingService.adListings.subscribe(val => {
      this.listings = val;
    });
  }

  goToAdSubmission() {
    this.router.navigateByUrl('/submitAD');
  }
}
