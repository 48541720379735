import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { BookInfo, BookInfoService } from '../services/book-info.service';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';

import { firestore } from 'firebase';

export class Register {
  UID: string;
  firstName: string;
  lastName: string;
  userType: string;
  jobTitle: string;
  personalWebsite: string;
  directPhone: string;
  payment: string;
  picture: string;
  background: string;
  email: string;
  currentSubmissionID: string;
  teamID: string;
  officeID: string;
  facebook: string;
  twitter: string;
  linkedIn: string;
}

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  private registerCollection: AngularFirestoreCollection<Register>;
  private registers: Observable<Register[]>;

  private userAdList: Register[] = [];

  bookInfo: BookInfo = new BookInfo();

  private teamMembers: Register[] = [];

  private hasBackground: boolean;

  constructor(
    private http: Http,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private bookInfoService: BookInfoService
  ) {
    this.registerCollection = db.collection<Register>('/Users');

    this.registers = this.registerCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getRegisters() {
    return this.registers;
  }

  getRegister(id) {
    return this.registerCollection.doc<Register>(id).valueChanges();
  }

  updateRegister(register: Register, id: string) {
    return this.registerCollection.doc(id).update(register);
  }

  removeProfilePic(id: string, downloadUrl: string) {
    this.storage.storage
      .refFromURL(downloadUrl)
      .delete()
      .then(() => {
        console.log('Deleted');
      });
  }

  removeBackgroundPic(downloadUrl: string) {
    this.storage.storage
      .refFromURL(downloadUrl)
      .delete()
      .then(() => {
        console.log('Deleted');
      });
  }

  updateProfilePic(id: string, download: string) {
    this.db
      .collection('/Users')
      .doc(id)
      .update({ picture: download });
  }

  updateBackgroundPic(id: string, download: string) {
    this.db
      .collection('/Users')
      .doc(id)
      .update({ background: download });
  }

  checkForBackground(UID: string) {
    this.db
      .collection('/Users')
      .doc(UID)
      .snapshotChanges()
      .subscribe(userInfo => {
        var user = userInfo.payload.data() as Register;
        if (user.background != undefined || user.background != '') {
          this.hasBackground = true;
        } else {
          this.hasBackground = false;
        }
      });
  }

  get beckgroundCheck() {
    return of(this.hasBackground);
  }

  addUser(register: Register, id: string, bookInfo: BookInfo) {
    var adSubID = this.db.createId();
    this.db
      .collection('/Ad-Submissions')
      .doc(adSubID)
      .set({
        AdSubmissionID: adSubID,
        //IssueID: bookInfo.IssueID, //Get issue ID and store the object in local storage.
        adSize: 0,
        isSubmitted: false,
        userID: id,
        toggleStep1Panel: true,
        toggleStep2Panel: true,
        toggleStep3Panel: true
      })
      .then(() => {
        this.db
          .collection('/Users')
          .doc(id)
          .set({
            UID: id,
            firstName: register.firstName,
            lastName: register.lastName,
            userType: register.userType,
            picture: register.picture,
            background: register.background,
            currentSubmissionID: adSubID
          });
      });
  }

  addUserProof(UID: string, downloadLink: string) {
    let currentIssueNum: string;
    this.bookInfoService.getCurrentIssue().subscribe(bookInfo => {
      var info = bookInfo[0].payload.doc.data() as BookInfo;
      currentIssueNum = info.IssueNum;
    });
    this.db
      .collection('/Users')
      .doc(UID)
      .snapshotChanges()
      .subscribe(user => {
        var tmpUser = user.payload.data() as Register;

        var AdProofID = this.db.createId();

        this.db
          //.collection('/Ad-Submissions')
          //.doc(tmpUser.currentSubmissionID)
          .collection('/Ad-Proof')
          .doc(AdProofID)
          .set({
            ID: AdProofID,
            UID: tmpUser.UID,
            AdProof: downloadLink,
            Timestamp: firestore.Timestamp.now(),
            //changes: [],
            proofChange: '',
            issueNumber: currentIssueNum
          });

        // API Request to send mail -----------------------------------------------------------------
        let data = {
          name: tmpUser.UID
        };

        this.http
          .post('http://homesplus.ddns.net:8080/homesplus', data)
          .pipe(map(res => res.json()))
          .subscribe(response => {
            console.log('POST Response:', response);
          });

        //this.http.get('http://localhost:8080/homesplus/' + data.name).pipe(
        //    map(res => res.json())
        //).subscribe(response => {
        //    console.log('GET Response:', response);
        //});
        // API Request to send mail -----------------------------------------------------------------
      });
  }

  addAgentInfo(user: Register, id: string) {
    return this.db
      .collection('/Users')
      .doc(id)
      .update(JSON.parse(JSON.stringify(user)));
  }

  removeRegister(id) {
    return this.registerCollection.doc(id).delete();
  }

  getAgents() {
    //this.db
    //  .collection('/User-Types', ref => ref.where('ShowInAdList', '==', true))
    //  .snapshotChanges()
    //  .subscribe(roles => {
    //    roles.forEach(role => {
    //      this.db
    //        .collection('/Users', ref =>
    //          ref.where('userType', '==', role.payload.doc.id)
    //        )
    //        .snapshotChanges()
    //        .subscribe(users => {
    //          users.forEach(user => {
    //            this.userAdList.push(user.payload.doc.data() as Register);
    //          });
    //        });
    //    });
    //  });

    this.db
      .collection('/Users', ref => ref.orderBy('lastName'))
      .snapshotChanges()
      .subscribe(users => {
        this.userAdList.length = 0;
        users.forEach(user => {
          this.userAdList.push(user.payload.doc.data() as Register);
        });
      });
  }

  get userList() {
    return of(this.userAdList);
  }

  userTeamPics(team: string) {
    if (team != 'undefined' && team != null) {
      this.db
        .collection('/Users', ref => ref.where('teamID', '==', team))
        .snapshotChanges()
        .subscribe(teamUsers => {
          teamUsers.forEach(teamUser => {
            this.teamMembers.push(teamUser.payload.doc.data() as Register);
          });
        });
    }
  }

  get teamPics() {
    return of(this.teamMembers);
  }
}
