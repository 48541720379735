import { Observable, Subject, interval } from 'rxjs';
import { BookInfo, BookInfoService } from '../services/book-info.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Listing, ListingService } from '../services/listings.service';
import { ToggleSideMenuService } from '../services/toggle-side-menu.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import {
  Ad_Proof,
  AdSizeDetails,
  Ad_Submission,
  AdSubmissionService,
} from '../services/ad-submission.service';

@Component({
  selector: 'app-assemble-ad',
  templateUrl: './assemble-ad.component.html',
  styleUrls: ['./assemble-ad.component.scss'],
})
export class AssembleAdComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  @Input() userID: string;
  @Input() admin: string;
  @Output() submitAdEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  addListingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  deleteListingEvent: EventEmitter<string> = new EventEmitter<string>();

  listings: Array<Listing> = new Array();

  currentSubmission: Ad_Submission = new Ad_Submission();
  bookInfo: BookInfo = new BookInfo();
  allAdSizes: AdSizeDetails[] = [];

  listingPrompt: boolean;
  isHovering: boolean;
  isDraggable: boolean; // Expansion Panel

  sizePrompt: boolean;
  deletePrompt: boolean;
  toDelete: string;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;

  prevAdSize: string;

  listingIssue: string;

  allFiles: File[];

  adProofs: Ad_Proof[] = [];
  orderedAdProofs: Ad_Proof[] = [];

  adSizeWarningEnabled: boolean;

  isListingTrashClicked: boolean;

  hovered: boolean;

  isAdmin: boolean;

  charLength: string;

  numOfPhoto: string;

  identifyer = (index: number, item: any) => item.name;

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private bookInfoService: BookInfoService,
    private listingService: ListingService,
    private adSubmissionService: AdSubmissionService,
    private toggleSideMenuService: ToggleSideMenuService
  ) {}

  ngOnInit() {
    this.isAdmin = false;
    this.hovered = false;
    this.getAdSubmissionInfo();
    this.getOrderedProofs();
    this.listingIssue = 'Invalid';
    this.sizePrompt = false;
    this.isDraggable = true;
    this.getAdListings();
    this.adSubmissionService.getAdSizePhotos();
    this.adSubmissionService.adSizeDetails.subscribe((adSizes) => {
      this.allAdSizes = adSizes;

      //Auto save:
      //var sub = interval(30000).subscribe(val => {
      //  this.updateAllListings();
      //});
    });

    var element = document.getElementById('notification-ani');
    element.addEventListener('animationend', this.listener, false);

    var elementProof = document.getElementById('notification-ani-proof');
    elementProof.addEventListener('animationend', this.proofListener, false);

    this.toggleSideMenuService.currentlyAdmin.subscribe(
      (admin) => (this.isAdmin = admin)
    );
  }

  listener(e) {
    var element = document.getElementById('notification-ani');
    element.classList.remove('animate');
  }

  proofListener(e) {
    var elementProof = document.getElementById('notification-ani-proof');
    elementProof.classList.remove('animate');
  }

  toggleDraggable(expanded: boolean) {
    this.isDraggable = !expanded;
  }

  toggleAdSizeWarning() {
    if (this.currentSubmission.adSize != 'Unlimited') {
      if (
        this.currentSubmission.userAdSize >
        Number(this.currentSubmission.adSize)
      ) {
        this.adSizeWarningEnabled = true;
      } else {
        this.adSizeWarningEnabled = false;
      }
    } else {
      this.adSizeWarningEnabled = false;
    }
  }

  toggleAR(index: number) {
    this.listings[index].hasAR = !this.listings[index].hasAR;
  }

  getAdListings() {
    this.listingService.getAdListings(this.userID);
    this.listingService.adListings.subscribe((val) => {
      this.listings = val;
    });
  }

  addListing() {
    this.listingPrompt = true;
    this.addListingChange.emit(this.listingPrompt);

    this.toggleAdSizeWarning();
  }

  deleteListing(listingID: string) {
    this.isListingTrashClicked = true; //when trash icon is clicked expansion is disabled
    this.deleteListingEvent.emit(listingID);
    //this.checkCurrentAdSize();
    this.toggleAdSizeWarning();
  }

  failedDeleteListing() {
    this.isListingTrashClicked = true; //when trash icon is clicked expansion is disabled
    console.log('Cannot delete submitted listing');
  }

  //sets the disabled property on the mat-expansion-panel
  toggleExpansionPanelDisable() {
    this.isListingTrashClicked = false;
  }

  updateListing(index: number) {
    if (
      this.listings[index].PhotoOption === 'I will email' &&
      this.listings[index].numOfPhoto == undefined
    ) {
      alert('Please specify number of photos you will email.');
    } else {
      this.formatPrice();
      this.listingService.updateListing(this.listings[index]);
      var element = document.getElementById('notification-ani');
      element.classList.add('animate');
    }
  }

  updateAllListings() {
    this.listingService.updateSubmissionListings(this.listings);
    this.adSubmissionService.updateSubmissionInfo(this.currentSubmission);
  }

  formatPrice() {
    this.listings.forEach((listing) => {
      //Remove letters and special chars
      if (listing.Price != undefined || listing.Price != null) {
        let tmpPrice = listing.Price.toString();
        tmpPrice = tmpPrice.replace(/[\s\D]/gi, '');
        var num = tmpPrice.replace(/,/gi, '');
        tmpPrice = num.split(/(?=(?:\d{3})+$)/).join(',');
        listing.Price = tmpPrice;
      } else {
        listing.Price = '0';
      }
      this.listingService.updateListing(listing);
    });
  }

  deletePhoto(photo: string, imgIndex: number, listingIndex: number) {
    //Delete photo from storage
    this.listingService.deletePhoto(photo);

    try {
      this.listings[listingIndex].assets.splice(imgIndex, 1);
      this.listings[listingIndex].includeImg.splice(imgIndex, 1);
    } catch (err) {
      console.log(err);
    }

    //Call update listings
    this.updateListing(listingIndex);

    //this.checkCurrentAdSize();
    this.adSubmissionService.modifyCurrentAdSize(this.currentSubmission, -1);
    this.toggleAdSizeWarning();
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  /*
    This method is the drop event for the drag and drop list
  */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listings, event.previousIndex, event.currentIndex);
    this.listingService.updateOrders(this.listings);
    //this.adSubmissionService.modifyCurrentAdSize(this.currentSubmission, 1);
  }

  onDrop(files: File[]) {
    this.allFiles = files;
  }

  //onDrop(files: File[], listingOrder: number) {
  //  //Rework this later
  //  for (var i = 0; i < files.length; i++) {
  //    const path = `listing/${Date.now()}_${files[i].name}`;
  //    const ref = this.storage.ref(path);
  //
  //    this.task = this.storage.upload(path, files[i]);
  //    this.percentage = this.task.percentageChanges();
  //    this.snapshot = this.task.snapshotChanges();
  //    this.task.then(() => {
  //      //this.assets.push(fileInfo.downloadURL);
  //      ref.getDownloadURL().subscribe(downloadURL => {
  //        this.listings[listingOrder].assets.push(downloadURL);
  //      });
  //    });
  //  }
  //}

  //TO DO: REWRITE THIS
  updateAdSize() {
    switch (this.currentSubmission.adSizeName) {
      case 'fullpage.png':
        this.currentSubmission.adSize = '16';
        this.currentSubmission.adDesc = 'Full Page';
        break;
      case 'halfpageH.png':
        this.currentSubmission.adSize = '8';
        this.currentSubmission.adDesc = 'Half Page Horizontal';
        break;
      case 'halfpageV.png':
        this.currentSubmission.adSize = '6';
        this.currentSubmission.adDesc = 'Half Page Vertical';
        break;
      case 'doublepage.png':
        this.currentSubmission.adSize = '32';
        this.currentSubmission.adDesc = 'Double Page';
        break;
      case 'quarterpage.png':
        this.currentSubmission.adSize = '3';
        this.currentSubmission.adDesc = 'Quarter Page';
        break;
      case 'singlelisting.png':
        this.currentSubmission.adSize = '1';
        this.currentSubmission.adDesc = 'Single Listing';
        break;
      case 'multipage.png':
        this.currentSubmission.adSize = '999';
        this.currentSubmission.adDesc = 'Multi Page';
        break;
    }

    this.prevAdSize = this.currentSubmission.adSizeName;
    this.currentSubmission.toggleStep1Panel = false;
    this.close();

    this.adSubmissionService.updateSubmissionInfo(this.currentSubmission);
  }

  panelValues(panel: string) {
    switch (panel) {
      case 'panel1':
        this.currentSubmission.toggleStep1Panel = !this.currentSubmission
          .toggleStep1Panel;
        break;
      case 'panel2':
        this.currentSubmission.toggleStep2Panel = !this.currentSubmission
          .toggleStep2Panel;
        break;
      case 'panel3':
        this.currentSubmission.toggleStep3Panel = !this.currentSubmission
          .toggleStep3Panel;
        break;
      case 'panel4':
        this.currentSubmission.toggleStep4Panel = !this.currentSubmission
          .toggleStep4Panel;
        break;
    }

    this.adSubmissionService.updateSubmissionInfo(this.currentSubmission);
  }

  close() {
    this.sizePrompt = false;
  }

  noOpt() {
    this.currentSubmission.adSizeName = this.prevAdSize;
    this.close();
  }

  getAdSubmissionInfo() {
    this.adSubmissionService
      .getCurrentSubmission(this.userID)
      .subscribe((val) => {
        this.currentSubmission = val[0].payload.doc.data() as Ad_Submission;
        this.adSubmissionService
          .getAdProofs(this.userID)
          .subscribe((proofs) => {
            this.adProofs = [];
            proofs.forEach((proof) => {
              var tmpProof = proof.payload.doc.data() as Ad_Proof;
              this.adProofs.push(tmpProof);
            });

            this.toggleAdSizeWarning();
          });
      });
  }

  getOrderedProofs() {
    let currentIssueNum: string;
    this.bookInfoService.getCurrentIssue().subscribe((bookInfo) => {
      var info = bookInfo[0].payload.doc.data() as BookInfo;
      currentIssueNum = info.IssueNum;
    });
    this.adSubmissionService.getAdProofs(this.userID).subscribe((proofs) => {
      this.orderedAdProofs = [];
      var tempProofArray = [];
      let counter = 1;
      proofs.forEach((proof) => {
        var tmpProof = proof.payload.doc.data() as Ad_Proof;
        if (tmpProof.issueNumber.toString() === currentIssueNum.toString()) {
          tempProofArray.push(tmpProof);
        }
      });
      console.log('Filtered by Issue Number : ', tempProofArray);
      tempProofArray.forEach((proof) => {
        if (counter !== tempProofArray.length) {
          proof.disabled = true;
        } else {
          proof.disabled = false;
        }
        this.orderedAdProofs.push(proof);
        counter++;
      });
      console.log('Disabled property added : ', this.orderedAdProofs);
    });
  }

  submitAd() {
    if (this.currentSubmission.adSize == '0') {
      alert('Please select ad size.');
      return;
    }

    for (let i = 0; i < this.listings.length; i++) {
      this.verifyListings(this.listings[i]);
      //this.formatPrice();
      if (this.listingIssue != 'valid') {
        alert(this.listingIssue);
        break;
      }
    }

    if (this.listingIssue == 'valid') {
      this.submitAdEvent.emit();
      this.currentSubmission.toggleStep1Panel = false;
      this.currentSubmission.toggleStep2Panel = false;
      this.currentSubmission.toggleStep3Panel = false;
      this.currentSubmission.toggleStep4Panel = false;
    }
  }

  calcLength(id: string) {
    const element = <HTMLInputElement>(
      document.getElementById(id + '-description')
    );
    this.charLength = element.value.length.toString();
    console.log(element);
  }

  saveStep4(adProof: Ad_Proof) {
    //this.adSubmissionService.updateSubmissionInfo(this.currentSubmission);
    this.adSubmissionService.updateAdProofs(adProof);
    var element = document.getElementById('notification-ani-proof');
    element.classList.add('animate');
    //console.log(adProof);
  }

  hoverImageEnter(id: string) {
    var element = document.getElementById(id + '-proofImg');
    element.classList.add('hoverOverlay');
    this.hovered = true;
  }

  hoverImageLeave(id: string) {
    var element = document.getElementById(id + '-proofImg');
    element.classList.remove('hoverOverlay');
    this.hovered = false;
  }

  imageClick(proofLink: string) {
    window.open(proofLink);
  }

  updateAdditionalInfo() {
    this.db
      .collection('/Ad-Submissions')
      .doc(this.currentSubmission.AdSubmissionID)
      .update({ AdditionalInfo: this.currentSubmission.AdditionalInfo });
  }

  //Work in progress
  verifyListings(listing: Listing) {
    if (listing.MLS != undefined) {
      if (listing.Address != undefined && listing.Address != '') {
        var cityRegex = /^[a-zA-Z\-', ]+$/;
        if (listing.City.match(cityRegex)) {
          this.listingIssue = 'valid';
        } else {
          this.listingIssue =
            'City_Error on the following listing: ' + listing.Address;
        }
      } else {
        this.listingIssue =
          'Address_Error on the following listing: ' + listing.Address;
      }
    } else {
      this.listingIssue =
        'MLS_Error on the following listing: ' + listing.Address;
    }
  }

  checkCurrentAdSize() {
    console.log('from assemble-ad : ' + this.currentSubmission);
    this.adSubmissionService.checkCurrentAdSize(
      this.currentSubmission,
      this.listings
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
