import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: Http, private https: HttpClient) {}

  userSubmit(UID: string) {
    console.log('RUNNING SUBMIT API');
    // this.http.get(`99.250.235.12:8080/submit/${UID}`).subscribe((response) => {
    //   console.log('GET Response:', response);
    // });
    this.https
      .get<any>(`https://homesplusmagazine.com:8081/submit/${UID}`)
      .subscribe((ret) => console.log(ret));
  }

  //User reviews proof -> admin receives email
  userProof(UID: string, changes: boolean) {
    this.http
      .get(`https://homesplusmagazine.com:8081/adminProof/${UID}/${changes}`)
      .subscribe((response) => {
        console.log('GET Response:', response);
      });
  }

  //Admin adds proof -> user receives email
  adminProof(UID: string) {
    this.http
      .get(`https://homesplusmagazine.com:8081/userProof/${UID}`)
      .subscribe((response) => {
        console.log('GET Response:', response);
      });
  }
}
