import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { SubmitADComponent } from './submit-ad/submit-ad.component';
import { ProfileComponent } from './profile/profile.component';
import { AdListComponent } from './ad-list/ad-list.component';
import { UsersAdsComponent } from './users-ads/users-ads.component';
import { ExportTextComponent } from './export-text/export-text.component';
import { UpdateDatabaseComponent } from './update-database/update-database.component';

import { AuthGuard } from './core/auth.guard';
import { AdProofComponent } from './ad-proof/ad-proof.component';

const routes: Routes = [
  { path: '', redirectTo: '/submitAD', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-pass', component: ForgotPassComponent },
  {
    path: 'submitAD',
    component: SubmitADComponent,
    canActivate: [AuthGuard],
  },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'adList', component: AdListComponent, canActivate: [AuthGuard] },
  { path: 'userAds', component: UsersAdsComponent, canActivate: [AuthGuard] },
  {
    path: 'exportText',
    component: ExportTextComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'updateDatabase',
    component: UpdateDatabaseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ad-proof',
    component: AdProofComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
