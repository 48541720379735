import { Injectable } from '@angular/core';
import { Register, RegisterService } from './register.service';
import { BookInfo, BookInfoService } from '../services/book-info.service';
import {
  Office,
  OrganizationsService
} from '../services/organizations.service';

@Injectable({
  providedIn: 'root'
})
export class QueryDatabaseService {
  bookInfo: BookInfo = new BookInfo();

  user: Register = new Register();
  userOffice: Office = new Office();

  constructor(
    private profile: RegisterService,
    private bookInfoService: BookInfoService,
    private organizationsService: OrganizationsService
  ) { }

  queryBookInfo() {
    return this.bookInfoService.getCurrentIssue();
  }

  queryAgentInformation(userDetails) {
    return this.profile.getRegister(JSON.parse(localStorage.getItem('UID')));
  }

  queryUserOffice(officeID) {
    return this.organizationsService.getOfficeByID(officeID);
  }
}
