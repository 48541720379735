import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class AuthService {
  userData: any;

  constructor(
    public router: Router,
    public afs: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('userLogin', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('userLogin'));

        localStorage.setItem(
          'UID',
          JSON.stringify(this.afAuth.auth.currentUser.uid)
        );
        JSON.parse(localStorage.getItem('UID'));
      } else {
        localStorage.setItem('userLogin', null);
        JSON.parse(localStorage.getItem('userLogin'));

        localStorage.setItem('UID', null);
        JSON.parse(localStorage.getItem('UID'));
      }
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('userLogin'));
    return user !== null ? true : false;
  }
}
