// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: 
  {
    apiKey: "AIzaSyAXNSdr5QdQwiztUE3oE848teQvB9cHzok",
    authDomain: "xplor-mvp-38458.firebaseapp.com",
    databaseURL: "https://xplor-mvp-38458.firebaseio.com",
    projectId: "xplor-mvp-38458",
    storageBucket: "xplor-mvp-38458.appspot.com",
    messagingSenderId: "915809046749",
    appId: "1:915809046749:web:7386df6da171a39d"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
