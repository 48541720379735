import { Component, OnInit } from '@angular/core';
import { BookInfo, BookInfoService } from '../services/book-info.service';
import {
  AdSubmissionService,
  Ad_Proof
} from '../services/ad-submission.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ad-proof',
  templateUrl: './ad-proof.component.html',
  styleUrls: ['./ad-proof.component.scss']
})
export class AdProofComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private adSubmissionService: AdSubmissionService,
    private bookInfoService: BookInfoService
  ) {}

  adProofs: Ad_Proof[] = [];
  proofStatus: string[] = [];
  UID: string;
  bookInfo: BookInfo = new BookInfo();

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.UID = params['UID'];
    });

    this.getProofsByUID();
  }

  getProofsByUID() {
    let currentIssueNum: string;
    this.bookInfoService.getCurrentIssue().subscribe(bookInfo => {
      var info = bookInfo[0].payload.doc.data() as BookInfo;
      currentIssueNum = info.IssueNum;
    });
    this.adSubmissionService.getAdProofs(this.UID).subscribe(proofs => {
      proofs.forEach(proof => {
        var tmpProof = proof.payload.doc.data() as Ad_Proof;
        if (tmpProof.issueNumber.toString() === currentIssueNum.toString()) {
          this.adProofs.push(tmpProof);
        }
      });
      this.getProofStatus();
    });
  }

  getProofStatus() {
    this.adProofs.forEach(proof => {
      console.log(proof.requiresChanges);
      switch (proof.requiresChanges) {
        case true:
          this.proofStatus.push('Requires Change');
          break;

        case false:
          console.log('False Case Reached');
          this.proofStatus.push('Approved');
          break;

        default:
          this.proofStatus.push('No Action Taken');
          break;
      }
      console.log(this.proofStatus);
    });
  }
}
