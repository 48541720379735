import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-login-suite',
  templateUrl: './login-suite.component.html',
  styleUrls: ['./login-suite.component.scss']
})
export class LoginSuiteComponent implements OnInit {
  isDivVisible;
  hideImg: boolean;

  constructor(public fAuth: AngularFireAuth, private router: Router) {}

  ngOnInit() {}

  backToLogin() {
    this.router.navigateByUrl('/login');
  }

  forgotPass() {
    this.router.navigateByUrl('/forgot-pass');
  }
}
