import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

export class UserType {
  HasTools: boolean;
  ID: string;
  Name: string;
  ShowInAdList: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  constructor(private db: AngularFirestore) {}

  getUserTypeDetails(ID: string) {
    return this.db
      .collection('/User-Types')
      .doc(ID)
      .snapshotChanges();
  }
}
