import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserType } from '../services/globals.service';
import { NavRoutes } from '../services/nav-routes.service';
import { Office } from '../services/organizations.service';
import { HasPictureService } from '../services/has-picture.service';
import { Register, RegisterService } from '../services/register.service';
import { ToggleSideMenuService } from '../services/toggle-side-menu.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @Input() username: string;
  @Input() NavigationRoutes: NavRoutes[];
  @Input() user: Register = new Register();
  @Input() userOffice: Office = new Office();
  @Input() userType: UserType = new UserType();
  @Output() profilePanelEvent: EventEmitter<any> = new EventEmitter<any>();

  editProfile: boolean;
  hasPicture: boolean;

  opened: boolean;
  hasTools: boolean;

  customStyle = {
    width: '100px',
    height: '100px',
    border: '5px solid rgba(255, 255, 255, 0.5)'
  };

  constructor(
    private router: Router,
    public fAuth: AngularFireAuth,
    private registerService: RegisterService,
    private hasPictureService: HasPictureService,
    private toggleSideMenuService: ToggleSideMenuService
  ) {}

  ngOnInit() {
    this.hasPictureService.currentlyHasPicture.subscribe(
      picture => (this.hasPicture = picture)
    );

    this.toggleSideMenuService.currentlytoggled.subscribe(
      toggled => (this.opened = toggled)
    );

    this.toggleSideMenuService.currentlyAdmin.subscribe(
      admin => (this.hasTools = admin)
    );
  }

  openProfilePanel() {
    this.editProfile = true;
    this.profilePanelEvent.emit(this.editProfile);
  }

  personalWebsite() {
    window.open('http://' + this.user.personalWebsite, '_blank');
  }

  logout() {
    return this.fAuth.auth.signOut().then(() => {
      localStorage.clear();
      this.router.navigateByUrl('/login');
    });
  }
}
