import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToggleSideMenuService {
  private toggleSideMenu = new BehaviorSubject<boolean>(true);
  currentlytoggled = this.toggleSideMenu.asObservable();

  private adminNav = new BehaviorSubject<boolean>(true);
  currentlyAdmin = this.adminNav.asObservable();

  constructor() {}

  sideMenuToggle(toggled: boolean) {
    this.toggleSideMenu.next(toggled);
  }

  adminSideNav(admin: boolean) {
    this.adminNav.next(admin);
  }
}
