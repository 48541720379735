import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Register, RegisterService } from '../services/register.service';
import { BookInfo, BookInfoService } from '../services/book-info.service';
import {
  Ad_Submission,
  AdSubmissionService
} from '../services/ad-submission.service';

export class newUser {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  Repassword: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  user: newUser = new newUser();
  register: Register = new Register();

  isDivVisible;

  bookInfo: BookInfo = new BookInfo();
  currentSubmission: Ad_Submission = new Ad_Submission();

  submissionID: string;

  constructor(
    private router: Router,
    private db: AngularFirestore,
    public fAuth: AngularFireAuth,
    private bookInfoService: BookInfoService,
    private registerService: RegisterService,
    private adSubmissionService: AdSubmissionService
  ) {}

  ngOnInit() {
    this.isDivVisible = false;
    this.getIssueDetails();
  }

  getIssueDetails() {
    this.bookInfoService.getCurrentIssue().subscribe(val => {
      this.bookInfo = val[0].payload.doc.data() as BookInfo;
    });
  }

  async registerUser() {
    if (this.user.password == this.user.Repassword) {
      if (
        this.user.firstName != undefined &&
        this.user.firstName != '' &&
        this.user.lastName != undefined &&
        this.user.lastName != ''
      ) {
        try {
          var r = await this.fAuth.auth
            .createUserWithEmailAndPassword(this.user.email, this.user.password)
            .then();
          {
            this.SendVerificationMail();
            //this.makeNewAdSubmission();
          }

          if (r) {
            this.register.UID = this.fAuth.auth.currentUser.uid; //Get user ID of last added user
            this.addUser(); // Add user details
            alert('Thank you for signing up!');
            this.router.navigateByUrl('/login');
          }
        } catch (err) {
          //this.isDivVisible = true;
          if (err.code === 'auth/email-already-in-use') {
            alert('Email Already In Use');
          }
          if (err.code === 'auth/weak-password') {
            alert('Weak Password. Password Should Be At Least 6 Characters');
          }
        }
      } else {
        alert('Please enter your First and Last Name!');
      }
    } else {
      alert('Your Passwords Do Not Match');
    }
  }

  async addUser() {
    //Set the user's first and last name
    this.register.firstName = this.user.firstName;
    this.register.lastName = this.user.lastName;
    this.register.currentSubmissionID = this.submissionID;
    this.register.picture = '';
    this.register.background =
      'https://firebasestorage.googleapis.com/v0/b/xplor-mvp-38458.appspot.com/o/background%2F1574370269752_brown-close-up-colors-2950003.jpg?alt=media&token=5740e457-0a48-43ca-8cde-db403f3132a2';

    //Get User Type then add user
    this.db
      .collection('/User-Types', ref => ref.where('Name', '==', 'Broker'))
      .get()
      .subscribe(querySnapshot => {
        //User type's ID
        this.register.userType = querySnapshot.docs[0].id;
        this.register.facebook = 'www.facebook.com';
        this.register.linkedIn = 'ca.linkedin.com';
        this.register.twitter = 'twitter.com';

        this.registerService.addUser(
          this.register,
          this.register.UID,
          this.bookInfo
        );
      });
  }

  SendVerificationMail() {
    return this.fAuth.auth.currentUser.sendEmailVerification().then(() => {});
  }

  backToLogin() {
    this.router.navigateByUrl('/login');
  }
}
