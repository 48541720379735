import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HasPictureService {
  private hasPicture = new BehaviorSubject<boolean>(true);
  currentlyHasPicture = this.hasPicture.asObservable();

  constructor() {}

  toggleHasPicture(picture: boolean) {
    this.hasPicture.next(picture);
  }
}
