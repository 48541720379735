import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { UserType, GlobalsService } from '../services/globals.service';
import { Listing, ListingService } from '../services/listings.service';
import { Register, RegisterService } from '../services/register.service';
import { BookInfo, BookInfoService } from '../services/book-info.service';
import { NavRoutes, NavRoutesService } from '../services/nav-routes.service';
import {
  Ad_Submission,
  AdSubmissionService
} from '../services/ad-submission.service';
import {
  Office,
  OrganizationsService
} from '../services/organizations.service';

@Component({
  selector: 'app-users-ads',
  templateUrl: './users-ads.component.html',
  styleUrls: ['./users-ads.component.scss']
})
export class UsersAdsComponent implements OnInit {
  newListing: Listing = new Listing();

  realtor: Register = new Register();
  user: Register = new Register();
  userType: UserType = new UserType();
  userOffice: Office = new Office();

  bookInfo: BookInfo = new BookInfo();
  listings: Array<Listing> = new Array();
  currentSubmission: Ad_Submission = new Ad_Submission();

  userHasPicture: boolean;
  hasPicture: boolean;
  userName: string;
  loggedInName: string;

  NavigationRoutes: NavRoutes[];

  editProfile: boolean;
  deletePrompt: boolean;
  submitPrompt: boolean;
  listingPrompt: boolean;
  editRealtorProfile: boolean;

  toDelete: string;

  constructor(
    private router: Router,
    public fAuth: AngularFireAuth,
    private profile: RegisterService,
    private listingService: ListingService,
    private activatedRoute: ActivatedRoute,
    private globalsService: GlobalsService,
    private bookInfoService: BookInfoService,
    private NavigationService: NavRoutesService,
    private adSubmissionService: AdSubmissionService,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.realtor.UID = params['UID'];
      this.realtor.firstName = params['firstName'];
      this.realtor.lastName = params['lastName'];
      this.realtor.email = params['email'];
      this.realtor.personalWebsite = params['personalWebsite'];
      this.realtor.officeID = params['officeID']; //QUERY FOR OFFICE
      this.realtor.directPhone = params['directPhone'];
      this.realtor.facebook = params['facebook'];
      this.realtor.twitter = params['twitter'];
      this.realtor.linkedIn = params['linkedIn'];
      this.realtor.picture = params['picture'];
    });

    this.getAgentInformation();
    this.getUserInfo();
    this.assembleLoggedInName();
    this.getAdSubmissionInfo();

    this.bookInfoService.getCurrentIssue().subscribe(val => {
      this.bookInfo = val[0].payload.doc.data() as BookInfo;

      this.NavigationService.Navs.subscribe(Navs => {
        this.NavigationRoutes = Navs;

        this.checkForProfPicture();
      });
    });
  }

  goToAdSubmission() {
    this.router.navigateByUrl('/submitAD');
  }

  assembleLoggedInName() {
    this.loggedInName = this.user.firstName + this.user.lastName;
  }

  //https://github.com/HaithemMosbahi/ngx-avatar
  checkForProfPicture() {
    if (
      this.realtor.picture == '' ||
      this.realtor.picture == null ||
      this.realtor.picture == undefined
    ) {
      this.hasPicture = false;

      this.userName = this.realtor.firstName + ' ' + this.realtor.lastName;
    } else {
      this.hasPicture = true;
    }
  }

  //https://github.com/HaithemMosbahi/ngx-avatar
  checkForUserProfPicture() {
    if (
      this.user.picture == '' ||
      this.user.picture == null ||
      this.user.picture == undefined
    ) {
      this.userHasPicture = false;

      this.loggedInName = this.user.firstName + ' ' + this.user.lastName;
    } else {
      this.userHasPicture = true;
    }
  }

  getAdSubmissionInfo() {
    this.adSubmissionService
      .getCurrentSubmission(this.realtor.UID)
      .subscribe(val => {
        this.currentSubmission = val[0].payload.doc.data() as Ad_Submission;
      });
  }

  logout() {
    return this.fAuth.auth.signOut().then(() => {
      localStorage.clear();
      this.router.navigateByUrl('/login');
    });
  }

  showAddListing($event) {
    this.listingPrompt = $event;
  }

  recieveNewListing($event) {
    this.newListing = $event;
    this.newListing.userID = this.realtor.UID;
    this.newListing.isActive = true;
    var data = this.newListing as Listing;

    let adSize = Number(this.currentSubmission.adSize);

    var order = this.listings.length;

    if (adSize > this.listings.length) {
      data.itemOrder = this.listings.length;
      window.scrollTo(0, document.body.scrollHeight); //Scroll the page down
      this.listingService.addProperty(data, this.realtor, order);
    } else {
      alert(
        "I'm sorry but you have too many listings in your ad.Please upgrade your ad size if you would like more listings to feature in your ad."
      );
    }

    this.getAdListings();
  }

  getAdListings() {
    this.listingService.getAdListings(this.realtor.UID);
    this.listingService.adListings.subscribe(val => {
      this.listings = val;
    });
  }

  getAgentInformation() {
    var userDetails = JSON.parse(localStorage.getItem('userLogin'));
    this.profile.getRegister(this.realtor.UID).subscribe(val => {
      this.realtor = val as Register;
      //this.realtor.email = userDetails.email;
      this.organizationsService
        .getOfficeByID(this.realtor.officeID)
        .subscribe(office => {
          this.userOffice = office.payload.data() as Office;
        });
    });
  }

  getUserInfo() {
    var userDetails = JSON.parse(localStorage.getItem('userLogin'));
    this.profile
      .getRegister(JSON.parse(localStorage.getItem('UID')))
      .subscribe(val => {
        this.user = val as Register;
        this.user.email = userDetails.email;
        this.organizationsService
          .getOfficeByID(val.officeID)
          .subscribe(office => {
            //this.userOffice = office.payload.data() as Office;

            this.globalsService
              .getUserTypeDetails(this.user.userType)
              .subscribe(val => {
                this.userType = val.payload.data() as UserType;

                if (this.userType.HasTools == false) this.goToAdSubmission();

                this.checkForUserProfPicture();
              });
          });
      });
  }

  personalWebsite() {
    window.open('http://' + this.user.personalWebsite, '_blank');
  }

  toggleProfilePrompt() {
    this.editProfile = true;
  }

  toggleRealtorPrompt() {
    this.editRealtorProfile = true;
  }

  toggleSubmitPrompt() {
    this.submitPrompt = true;
  }

  submitAd() {
    let adSize = Number(this.currentSubmission.adSize);

    this.currentSubmission.waiting = false;
    this.listings.forEach(listing => {
      if (
        listing.PhotoOption == 'Take For Me' ||
        listing.PhotoOption == 'Email Later'
      ) {
        this.currentSubmission.waiting = true;
      }
    });

    if (adSize > this.listings.length) {
      this.adSubmissionService.updateSubmissionInfo(this.currentSubmission);
      this.adSubmissionService.submitAd(this.currentSubmission); //Change isSubmitted to true
      this.close();
    } else {
      alert(
        "I'm sorry but you have too many listings in your ad.Please upgrade your ad size if you would like more listings to feature in your ad."
      );
    }
  }

  closeBtn($event) {
    this.toDelete = $event;
    this.deletePrompt = true;
  }

  yesOpt() {
    this.listingService.deactivateListing(this.toDelete);
    this.deletePrompt = false;
  }

  close() {
    this.deletePrompt = false;
    this.submitPrompt = false;
  }
}
