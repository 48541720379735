import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { Register } from '../services/register.service';
import { BookInfo } from '../services/book-info.service';
import { HasPictureService } from '../services/has-picture.service';
import { ToggleSideMenuService } from '../services/toggle-side-menu.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  @Input() user: Register = new Register();
  @Input() username: string;
  @Input() bookInfo: BookInfo = new BookInfo();
  @Output() profilePanelEvent: EventEmitter<any> = new EventEmitter<any>();

  hasPicture: boolean;

  opened: boolean;

  constructor(
    private router: Router,
    private hasPictureService: HasPictureService,
    private toggleSideMenuService: ToggleSideMenuService
  ) {}

  ngOnInit() {
    this.toggleSideMenuService.sideMenuToggle(false);
    this.bookInfo.IssueID = '';
    this.bookInfo.DistributionStartDate = new firebase.firestore.Timestamp(
      1,
      1
    );
    this.bookInfo.DistributionEndDate = '';
    this.bookInfo.IssueNum = '';
    this.bookInfo.SubmissionDate = new firebase.firestore.Timestamp(1, 1);
    this.bookInfo.DateAdded = new firebase.firestore.Timestamp(1, 1);

    this.hasPictureService.currentlyHasPicture.subscribe(
      picture => (this.hasPicture = picture)
    );
  }

  openProfilePanel() {
    this.profilePanelEvent.emit();
  }

  goToSubmitAd() {
    this.router.navigateByUrl('/submitAD');
  }

  toggleMenu() {
    this.opened = !this.opened;
    this.toggleSideMenuService.sideMenuToggle(this.opened);
  }
}
