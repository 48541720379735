import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

export class Office {
  ID: string;
  Name: string;
  Address: string;
  PhoneNumber: string;
  Website: string;
  Email: string;
  Logo: string;
}

export class Team {
  ID: string;
  Name: string;
  PhoneNumber: string;
  Website: string;
  Email: string;
  Assistant: string;
  Photo: string;
  Logo: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  private allOffices: Office[] = [];
  private allTeams: Team[] = [];

  constructor(private db: AngularFirestore) { }

  //Getters
  get Offices() {
    return of(this.allOffices);
  }

  get Teams() {
    return of(this.allTeams);
  }

  /* Office Methods */
  getOfficeByName(officeName: string) {
    return this.db
      .collection('/Offices', ref => ref.where('Name', '==', officeName))
      .snapshotChanges();
  }

  getOfficeByID(officeID: string) {
    return this.db
      .collection('/Offices')
      .doc(officeID)
      .snapshotChanges();
  }

  addOffice(office: Office, officeID: string) {
    this.db
      .collection('/Offices')
      .doc(officeID)
      .set({
        ID: officeID,
        Name: office.Name,
        Address: office.Address,
        PhoneNumber: office.PhoneNumber,
        website: office.Website,
        Email: office.Email
      });
  }

  getAllOffice() {
    this.db
      .collection('/Offices')
      .snapshotChanges()
      .subscribe(offices => {
        this.allOffices.length = 0;
        offices.forEach(office => {
          this.allOffices.push(office.payload.doc.data() as Office);
        });
      });
  }

  addOfficeLogo(officeID: string, downloadLink: string) {
    return this.db
      .collection('/Offices')
      .doc(officeID)
      .update({ Logo: downloadLink });
  }
  /* Office Methods */

  /* Team Methods */
  getTeamByName(teamName: string) {
    return this.db
      .collection('/Teams', ref => ref.where('Name', '==', teamName))
      .snapshotChanges();
  }

  addTeam(team: Team, teamID: string) {
    this.db
      .collection('/Teams')
      .doc(teamID)
      .set({
        ID: teamID,
        Name: team.Name,
        PhoneNumber: team.PhoneNumber,
        Website: team.Website,
        Email: team.Email
      });
  }

  getAllTeams() {
    this.db
      .collection('/Teams')
      .snapshotChanges()
      .subscribe(teams => {
        this.allTeams.length = 0;
        teams.forEach(team => {
          this.allTeams.push(team.payload.doc.data() as Team);
        });
      });
  }

  addTeamPhoto(teamID: string, downloadLink: string) {
    return this.db
      .collection('/Teams')
      .doc(teamID)
      .update({ Photo: downloadLink });
  }

  addTeamLogo(teamID: string, downloadLink: string) {
    return this.db
      .collection('/Teams')
      .doc(teamID)
      .update({ Logo: downloadLink });
  }
  /* Team Methods */
}
