import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss']
})
export class ForgotPassComponent implements OnInit {
  email: string;
  isDivVisible: boolean;

  constructor(public fAuth: AngularFireAuth, private router: Router) { }

  ngOnInit() {
    this.isDivVisible = false;
  }

  resetPassword() {
    var auth = this.fAuth.auth;

    if (this.email == undefined) {
      this.isDivVisible = true;
    }

    return auth
      .sendPasswordResetEmail(this.email)
      .then(() => {
        alert('Password reset email sent to ' + this.email);
        this.backToLogin();
      })
      .catch(error => {
        console.log(error);
        this.isDivVisible = true;
      });
  }

  backToLogin() {
    this.router.navigateByUrl('/login');
  }
}
