import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { Injectable } from '@angular/core';
import { Timestamp } from '@firebase/firestore-types';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';

export class BookInfo {
  IssueID: string;
  DistributionStartDate: Timestamp;
  DistributionEndDate: string;
  IssueNum: string;
  SubmissionDate: Timestamp;
  DateAdded: Timestamp;
}

@Injectable({
  providedIn: 'root'
})
export class BookInfoService {
  private issueCollection: AngularFirestoreCollection<BookInfo>;
  private issues: Observable<BookInfo[]>;

  public releaseAds: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  test: firebase.firestore.Timestamp;

  constructor(private db: AngularFirestore) {
    this.issueCollection = db.collection<BookInfo>('Book-Info');

    this.issues = this.issueCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getIssues() {
    return this.issues;
  }

  getIssue(id) {
    return this.issueCollection.doc<BookInfo>(id).valueChanges();
  }

  getCurrentIssue() {
    return this.db
      .collection('/Book-Info', ref =>
        ref.orderBy('DateAdded', 'desc').limit(1)
      )
      .snapshotChanges();
  }

  updateIssue(issue: BookInfo, id: string) {
    return this.issueCollection.doc(id).update(issue);
  }

  addIssue(issue: BookInfo, adDeadline: Date) {
    issue.IssueID = this.db.createId();
    issue.DateAdded = firebase.firestore.FieldValue.serverTimestamp() as Timestamp;
    issue.SubmissionDate = new firebase.firestore.Timestamp(
      adDeadline.getTime() / 1000, //Seconds
      adDeadline.getSeconds() //Honestly idk what this is for but it works sooooo?
    );
    this.issueCollection.add({ ...issue });
  }

  removeIssue(id) {
    return this.issueCollection.doc(id).delete();
  }
}
