import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { AngularFireModule } from '@angular/fire/';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage'; // import angular firebase file storage module

import { environment } from '../environments/environment';
import { RegisterComponent } from './register/register.component';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { SubmitADComponent } from './submit-ad/submit-ad.component';
import { ProfileComponent } from './profile/profile.component';
import { AdListComponent } from './ad-list/ad-list.component';

import {
  MatRadioModule,
  MatCheckboxModule,
  MatToolbarModule
} from '@angular/material';
import { MatCardModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropzoneDirective } from './dropzone.directive';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material';
import { UsersAdsComponent } from './users-ads/users-ads.component';
import { ExportTextComponent } from './export-text/export-text.component';

import { GlobalsService } from './services/globals.service';

import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { UpdateDatabaseComponent } from './update-database/update-database.component';

import { MatDialogModule } from '@angular/material/dialog';

import { MatToolbar } from '@angular/material';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './services/auth.service';

import { HttpClientModule } from '@angular/common/http';
import { AvatarModule } from 'ngx-avatar';

import { DeviceDetectorModule } from 'ngx-device-detector';

import { MatTooltipModule } from '@angular/material/tooltip';
import { LoginSuiteComponent } from './login-suite/login-suite.component';
import { PromptBoxComponent } from './prompt-box/prompt-box.component';
import { ListingPopupComponent } from './listing-popup/listing-popup.component';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { SideBarComponent } from './side-bar/side-bar.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { AssembleAdComponent } from './assemble-ad/assemble-ad.component';
import { ImgUploadComponent } from './img-upload/img-upload.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatSortModule } from '@angular/material/sort';

import { HttpModule } from '@angular/http';
import { Http } from '@angular/http';
import { AdProofComponent } from './ad-proof/ad-proof.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPassComponent,
    SubmitADComponent,
    ProfileComponent,
    AdListComponent,
    DropzoneDirective,
    UsersAdsComponent,
    ExportTextComponent,
    UpdateDatabaseComponent,
    LoginSuiteComponent,
    PromptBoxComponent,
    ListingPopupComponent,
    SideBarComponent,
    TopBarComponent,
    AssembleAdComponent,
    ImgUploadComponent,
    AdProofComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    MatRadioModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatInputModule,
    MatToolbarModule,
    MatExpansionModule,
    MatDatepickerModule,
    DragDropModule,
    ShowHidePasswordModule,
    MatTableModule,
    MatPaginatorModule,
    HttpClientModule,
    AvatarModule,
    DeviceDetectorModule.forRoot(),
    MatTooltipModule,
    MatDialogModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSortModule,
    MatSlideToggleModule,
    HttpModule,
    MatSidenavModule
  ],
  providers: [GlobalsService, AuthGuard, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule {}
