import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Office } from '../services/organizations.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { HasPictureService } from '../services/has-picture.service';
import { RegisterService, Register } from '../services/register.service';
import { BookInfo, BookInfoService } from '../services/book-info.service';
import { trigger, style, transition, animate } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class ProfileComponent implements OnInit {
  @Input() closable = true;
  @Input() visible: boolean;
  @Input() user: Register = new Register();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  fileName: string;
  files: File[] = [];

  isHovering: boolean;

  //user: Register = new Register();
  bookInfo: BookInfo = new BookInfo();

  allOffices: Office[] = [];

  constructor(
    private db: AngularFirestore,
    private router: Router,
    public fAuth: AngularFireAuth,
    private profile: RegisterService,
    private storage: AngularFireStorage,
    private bookInfoService: BookInfoService,
    private hasPictureService: HasPictureService
  ) {}

  ngOnInit() {
    this.getBookInfo();

    //Populate the text boxes and Image
    //this.getAgentInformation();

    this.getOffices();
  }

  //Close the dialog box
  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  getOffices() {
    this.db
      .collection('/Offices', ref => ref.orderBy('Name'))
      .snapshotChanges()
      .subscribe(offices => {
        this.allOffices.length = 0;
        offices.forEach(office => {
          this.allOffices.push(office.payload.doc.data() as Office);
        });
      });
  }

  getBookInfo() {
    this.bookInfoService.getCurrentIssue().subscribe(val => {
      this.bookInfo = val[0].payload.doc.data() as BookInfo;
    });
  }

  UpdateAgentInfo() {
    this.profile.addAgentInfo(this.user, this.user.UID);

    this.close();
  }

  goToAdSubmission() {
    this.router.navigateByUrl('/submitAD');
  }

  logout() {
    return this.fAuth.auth.signOut().then(() => {
      this.router.navigateByUrl('/login');
    });
  }

  uploadFile(event: FileList) {
    // The File object
    const file = event.item(0);

    // Validation for Images Only
    if (file.type.split('/')[0] !== 'image') {
      console.error('Unsupported file type :( ');
      return;
    }

    this.fileName = file.name;

    // The storage path
    const path = `image/${new Date().getTime()}_${file.name}`;

    // Totally optional metadata
    const customMetadata = { app: 'Image Upload' };

    //File reference
    const fileRef = this.storage.ref(path);

    const id = this.user.UID;
    // The main task
    this.storage.upload(path, file, { customMetadata }).then(() => {
      fileRef.getDownloadURL().subscribe(val => {
        try {
          //Delete old Profile Pic
          if (this.user.picture != '') {
            this.profile.removeProfilePic(id, this.user.picture);
          }

          //Update to new Profile Pic
          this.user.picture = val;
          this.profile.updateProfilePic(id, val);
          this.hasPictureService.toggleHasPicture(true);
        } catch (err) {
          console.log('Error: ' + err);
        }
      });
    });
  }

  uploadBackground(event: FileList) {
    // The File object
    const file = event.item(0);

    // Validation for Images Only
    if (file.type.split('/')[0] !== 'image') {
      console.error('Unsupported file type :( ');
      return;
    }

    this.fileName = file.name;

    // The storage path
    const path = `background/${new Date().getTime()}_${file.name}`;

    // Totally optional metadata
    const customMetadata = { app: 'Image Upload' };

    //File reference
    const fileRef = this.storage.ref(path);

    const id = this.user.UID;
    // The main task
    this.storage.upload(path, file, { customMetadata }).then(() => {
      fileRef.getDownloadURL().subscribe(val => {
        try {
          //Delete old Profile Pic
          if (this.user.background != '') {
            this.profile.removeBackgroundPic(this.user.background);
          }

          //Update to new Profile Pic
          this.profile.updateBackgroundPic(id, val);
        } catch (err) {
          console.log('Error: ' + err);
        }
      });
    });
  }

  forgotPass() {
    this.router.navigateByUrl('/forgot-pass');
  }
}
