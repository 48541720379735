import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

export class NavRoutes {
  name: string;
  route: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavRoutesService {
  AllNavs: NavRoutes[];

  constructor(private db: AngularFirestore) {
    this.AllNavs = [
      //{ name: 'Profile', route: 'http://localhost:4200/#/profile' }
      {
        name: 'Ad List',
        route: './#/adList'
      },
      {
        name: 'Update Database',
        route: './#/updateDatabase'
      }
    ];
  }

  get Navs() {
    return of(this.AllNavs);
  }
}
