import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { OnInit, Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HasPictureService } from '../services/has-picture.service';
import { Listing, ListingService } from '../services/listings.service';
import { UserType, GlobalsService } from '../services/globals.service';
import { Register, RegisterService } from '../services/register.service';
import { BookInfo, BookInfoService } from '../services/book-info.service';
import { NavRoutes, NavRoutesService } from '../services/nav-routes.service';
import { ToggleSideMenuService } from '../services/toggle-side-menu.service';
import {
  Ad_Submission,
  AdSubmissionService
} from '../services/ad-submission.service';
import {
  Office,
  OrganizationsService
} from '../services/organizations.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-submit-ad',
  templateUrl: './submit-ad.component.html',
  styleUrls: ['./submit-ad.component.scss']
})
export class SubmitADComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  constructor(
    private router: Router,
    public fAuth: AngularFireAuth,
    private profile: RegisterService,
    private globalsService: GlobalsService,
    private listingService: ListingService,
    private bookInfoService: BookInfoService,
    private NavigationService: NavRoutesService,
    private hasPictureService: HasPictureService,
    private adSubmissionService: AdSubmissionService,
    private organizationsService: OrganizationsService,
    private toggleSideMenuService: ToggleSideMenuService,
    private apiSerivce: ApiService
  ) {}

  newListing: Listing = new Listing();

  userID: string;

  user: Register = new Register();
  userType: UserType = new UserType();
  userOffice: Office = new Office();

  bookInfo: BookInfo = new BookInfo();
  listings: Array<Listing> = new Array();
  currentSubmission: Ad_Submission = new Ad_Submission();

  hasPicture: boolean;
  userName: string;

  NavigationRoutes: NavRoutes[];

  editProfile: boolean;
  deletePrompt: boolean;
  submitPrompt: boolean;
  listingPrompt: boolean;
  sizePrompt: boolean;

  toDelete: string;

  ngOnInit() {
    this.userID = JSON.parse(localStorage.getItem('UID'));
    this.getAgentInformation();
    this.getAdListings();
    this.bookInfoService.getCurrentIssue().subscribe(val => {
      this.bookInfo = val[0].payload.doc.data() as BookInfo;
      this.getAdSubmissionInfo();
      this.profile.userTeamPics(this.user.teamID);
      this.profile.checkForBackground(this.user.UID);
      this.globalsService
        .getUserTypeDetails(this.user.userType)
        .subscribe(val => {
          this.checkForProfPicture();
          this.userType = val.payload.data() as UserType;

          this.toggleSideMenuService.adminSideNav(this.userType.HasTools);

          this.NavigationService.Navs.subscribe(Navs => {
            this.NavigationRoutes = Navs;

            this.hasPictureService.currentlyHasPicture.subscribe(
              picture => (this.hasPicture = picture)
            );

            this.checkCurrentAdSize();
          });
        });
    });
  }

  //https://github.com/HaithemMosbahi/ngx-avatar
  checkForProfPicture() {
    if (
      this.user.picture == '' ||
      this.user.picture == null ||
      this.user.picture == undefined
    ) {
      //this.hasPicture = false;

      this.hasPictureService.toggleHasPicture(false);

      this.userName = this.user.firstName + ' ' + this.user.lastName;
    } else {
      //this.hasPicture = true;
      this.hasPictureService.toggleHasPicture(true);
    }
  }

  getAdSubmissionInfo() {
    this.adSubmissionService
      .getCurrentSubmission(JSON.parse(localStorage.getItem('UID')))
      .subscribe(val => {
        this.currentSubmission = val[0].payload.doc.data() as Ad_Submission;
      });
  }

  //logout() {
  //  return this.fAuth.auth.signOut().then(() => {
  //    localStorage.clear();
  //    this.router.navigateByUrl('/login');
  //  });
  //}

  showAddListing($event) {
    this.listingPrompt = $event;

    //this.checkCurrentAdSize();
  }

  recieveNewListing($event) {
    this.newListing = $event;
    this.newListing.isActive = true;
    var data = this.newListing as Listing;

    let adSize = Number(this.currentSubmission.adSize);

    var order = this.listings.length;

    var additionalSlots = 1;
    if (this.newListing.assets.length > 1) {
      additionalSlots = this.newListing.assets.length;
    }

    if (adSize > this.listings.length) {
      window.scrollTo(0, document.body.scrollHeight); //Scroll the page down
      this.listingService.addProperty(data, this.user, order);
      this.adSubmissionService.modifyCurrentAdSize(
        this.currentSubmission,
        additionalSlots
      );
    } else {
      alert(
        "I'm sorry but you have too many listings in your ad. Please upgrade your ad size if you would like more listings to feature in your ad."
      );
    }

    this.getAdListings();
  }

  getAdListings() {
    this.listingService.getAdListings(JSON.parse(localStorage.getItem('UID')));
    this.listingService.adListings.subscribe(val => {
      this.listings = val;
    });
  }

  getAgentInformation() {
    var userDetails = JSON.parse(localStorage.getItem('userLogin'));
    this.profile
      .getRegister(JSON.parse(localStorage.getItem('UID')))
      .subscribe(val => {
        this.user = val as Register;
        this.user.email = userDetails.email;
        this.organizationsService
          .getOfficeByID(val.officeID)
          .subscribe(office => {
            this.userOffice = office.payload.data() as Office;
          });
      });
  }

  //personalWebsite() {
  //  window.open('http://' + this.user.personalWebsite, '_blank');
  //}

  toggleProfilePrompt() {
    this.editProfile = true;
  }

  toggleSubmitPrompt() {
    this.submitPrompt = true;
  }

  toggleHasPicture() {
    this.hasPicture = true;
  }

  submitAd() {
    var adSize;
    if (this.currentSubmission.adSize != 'Unlimited') {
      adSize = Number(this.currentSubmission.adSize);
    } else {
      adSize = 9999;
    }

    this.currentSubmission.waiting = false;
    this.listings.forEach(listing => {
      if (
        listing.PhotoOption == 'Take For Me' ||
        listing.PhotoOption == 'Email Later'
      ) {
        this.currentSubmission.waiting = true;
      }
    });

    if (adSize >= /*this.listings.length*/ this.currentSubmission.userAdSize) {
      //this.adSubmissionService.updateSubmissionInfo(this.currentSubmission);
      this.adSubmissionService.submitAd(this.currentSubmission); //Change isSubmitted to true
      this.close();
      this.apiSerivce.userSubmit(this.user.UID);
    } else {
      alert(
        "I'm sorry but you have too many listings in your ad.Please upgrade your ad size if you would like more listings to feature in your ad."
      );
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  checkCurrentAdSize() {
    this.adSubmissionService.checkCurrentAdSize(
      this.currentSubmission,
      this.listings
    );
  }

  closeBtn($event) {
    this.toDelete = $event;
    this.deletePrompt = true;
  }

  yesOpt() {
    this.listingService.deactivateListing(this.toDelete);
    //this.checkCurrentAdSize();
    this.adSubmissionService.modifyCurrentAdSize(this.currentSubmission, -1);
    this.deletePrompt = false;
  }

  close() {
    this.deletePrompt = false;
    this.submitPrompt = false;
    this.sizePrompt = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
