import { Component, OnInit } from '@angular/core';
import { Listing } from '../services/listings.service';
import { Register } from '../services/register.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { RegisterService } from '../services/register.service';
import { Ad_Submission } from '../services/ad-submission.service';
import { UserType, GlobalsService } from '../services/globals.service';
import { QueryDatabaseService } from '../services/query-database.service';
import { BookInfoService, BookInfo } from '../services/book-info.service';
import { NavRoutes, NavRoutesService } from '../services/nav-routes.service';
import {
  OrganizationsService,
  Office,
  Team
} from '../services/organizations.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-update-database',
  templateUrl: './update-database.component.html',
  styleUrls: ['./update-database.component.scss']
})
export class UpdateDatabaseComponent implements OnInit {
  fileList: FileList;
  teamPhoto: FileList;
  TeamLogo: FileList;
  proofPic: FileList;

  issue: BookInfo = new BookInfo();
  office: Office = new Office();
  team: Team = new Team();

  teamID: string;
  officeID: string;

  allOffices: Office[] = [];
  allTeams: Team[] = [];
  allUsers: Register[] = [];
  allAdsubmissions: Ad_Submission[] = [];

  officeIndex: number;
  teamIndex: number;
  userOfficeIndex: number;
  userTeamIndex: number;
  userProofIndex: number;
  adminIndex: number;

  userReleaseIndex: number;

  unlockUser: Register = new Register();

  adDeadline: Date = new Date();

  bookInfo: BookInfo = new BookInfo();

  user: Register = new Register();
  hasPicture: boolean;
  userName: string;
  userOffice: Office = new Office();
  editProfile: boolean;

  userType: UserType = new UserType();
  NavigationRoutes: NavRoutes[];

  constructor(
    private router: Router,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private globalsService: GlobalsService,
    private registerService: RegisterService,
    private bookInfoService: BookInfoService,
    private navRoutesService: NavRoutesService,
    private queryDatabaseService: QueryDatabaseService,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit() {
    this.queryDatabaseInfo();
    this.officeIndex = 0;
    this.teamIndex = 0;
    this.userOfficeIndex = 0;
    this.userTeamIndex = 0;
    this.getOffices();
    this.getTeams();
    this.getUsers();
    this.getAdSubmissions();
  }

  queryDatabaseInfo() {
    var userDetails = JSON.parse(localStorage.getItem('userLogin'));
    this.queryDatabaseService.queryBookInfo().subscribe(val => {
      this.bookInfo = val[0].payload.doc.data() as BookInfo;
    });

    this.queryDatabaseService
      .queryAgentInformation(userDetails)
      .subscribe(val => {
        this.user = val as Register;
        this.user.email = userDetails.email;
        this.checkForProfPicture();

        this.queryDatabaseService
          .queryUserOffice(this.user.officeID)
          .subscribe(office => {
            this.userOffice = office.payload.data() as Office;
          });

        this.globalsService
          .getUserTypeDetails(this.user.userType)
          .subscribe(val => {
            this.userType = val.payload.data() as UserType;
          });
      });

    this.navRoutesService.Navs.subscribe(Navs => {
      this.NavigationRoutes = Navs;
    });
  }

  toggleProfilePrompt() {
    this.editProfile = true;
  }

  //REWRITE THIS
  //https://github.com/HaithemMosbahi/ngx-avatar
  checkForProfPicture() {
    if (
      this.user.picture == '' ||
      this.user.picture == null ||
      this.user.picture == undefined
    ) {
      this.hasPicture = false;

      this.userName = this.user.firstName + ' ' + this.user.lastName;
    } else {
      this.hasPicture = true;
    }
  }

  updateBookInfo() {
    this.bookInfoService.addIssue(this.issue, this.adDeadline);
    alert('Added new Issue!');
  }

  addOffice() {
    this.officeID = this.db.createId();
    this.organizationsService.addOffice(this.office, this.officeID);
    this.uploadFile(this.fileList, 'office_logo');
    alert('Added new Office!');
  }

  addTeam() {
    this.teamID = this.db.createId();
    this.organizationsService.addTeam(this.team, this.teamID);
    this.uploadFile(this.teamPhoto, 'team_photo');
    this.uploadFile(this.TeamLogo, 'team_logo');
    alert('Added new Team!');
  }

  addFile(event: FileList) {
    this.fileList = null;
    this.fileList = event;
  }

  addTeamPhoto(event: FileList) {
    this.teamPhoto = null;
    this.teamPhoto = event;
  }

  addTeamLogo(event: FileList) {
    this.TeamLogo = null;
    this.TeamLogo = event;
  }

  uploadFile(event: FileList, folder: string) {
    // The File object
    const file = event.item(0);
    if (file.type.split('/')[0] !== 'image') {
      console.error('Unsupported file type :( ');
      return;
    }

    const path = `${folder}/${new Date().getTime()}_${file.name}`; // The storage path
    const fileRef = this.storage.ref(path); //File reference

    // The main task
    this.storage.upload(path, file).then(() => {
      fileRef.getDownloadURL().subscribe(val => {
        switch (folder) {
          case 'office_logo':
            this.organizationsService.addOfficeLogo(this.officeID, val);
            break;
          case 'team_photo':
            this.organizationsService.addTeamPhoto(this.teamID, val);
            break;
          case 'team_logo':
            this.organizationsService.addTeamLogo(this.teamID, val);
            break;
          case 'user_proof':
            this.registerService.addUserProof(
              this.allUsers[this.userProofIndex].UID,
              val
            );
            break;
        }
      });
    });
  }

  updateUserReleaseIndex(index: number) {
    this.userReleaseIndex = index;
  }

  updateAdminIndex(index: number) {
    this.adminIndex = index;
  }

  removeAdmin() {
    alert('User has been removed from Admin list');

    this.db
      .collection('/Users')
      .doc(this.allUsers[this.adminIndex].UID)
      .update({ userType: 'T120tOsiGWudn1bcnnjH' });
  }

  addAdmin() {
    alert('User has been made an Admin');

    this.db
      .collection('/Users')
      .doc(this.allUsers[this.adminIndex].UID)
      .update({ userType: 'KN99X2vQu1qAWG9QqdBC' });
  }

  proofPhoto(event: FileList) {
    this.proofPic = null;
    this.proofPic = event;
  }

  addUserProof() {
    this.uploadFile(this.proofPic, 'user_proof');
    alert('Ad proof sent!');
  }

  getOffices() {
    this.db
      .collection('/Offices', ref => ref.orderBy('Name'))
      .snapshotChanges()
      .subscribe(offices => {
        this.allOffices.length = 0;
        offices.forEach(office => {
          this.allOffices.push(office.payload.doc.data() as Office);
        });
      });
  }

  getTeams() {
    this.db
      .collection('/Teams')
      .snapshotChanges()
      .subscribe(teams => {
        teams.forEach(team => {
          this.allTeams.push(team.payload.doc.data() as Team);
        });
      });
  }

  getUsers() {
    this.allUsers.length = 0;
    this.db
      .collection('/Users')
      .snapshotChanges()
      .subscribe(users => {
        this.allUsers.length = 0;
        users.forEach(user => {
          this.allUsers.push(user.payload.doc.data() as Register);
        });
      });
  }

  getAdSubmissions() {
    this.db
      .collection('/Ad-Submissions')
      .snapshotChanges()
      .subscribe(adSubmissions => {
        this.allAdsubmissions.length = 0;
        adSubmissions.forEach(adSubmission => {
          this.allAdsubmissions.push(
            adSubmission.payload.doc.data() as Ad_Submission
          );
        });
      });
  }

  updateNameID(index: number, type: string) {
    if (type == 'office') {
      this.userOfficeIndex = index;
    } else {
      this.userTeamIndex = index;
    }
  }

  updateOfficeID(index: number) {
    this.officeIndex = index;
  }

  updateTeamID(index: number) {
    this.teamIndex = index;
  }

  updateProofIndex(index: number) {
    this.userProofIndex = index;
  }

  addUserToOffice() {
    //Add office ID to user
    this.db
      .collection('/Users')
      .doc(this.allUsers[this.userOfficeIndex].UID)
      .update({ officeID: this.allOffices[this.officeIndex].ID });

    alert('Added user to Office!');
  }

  addUserToTeams() {
    //Add team ID to user
    this.db
      .collection('/Users')
      .doc(this.allUsers[this.userTeamIndex].UID)
      .update({ teamID: this.allTeams[this.teamIndex].ID });

    //Add user to team
    this.db
      .collection('/Teams')
      .doc(this.allTeams[this.teamIndex].ID)
      .collection('/Team-Members')
      .doc(this.allUsers[this.userTeamIndex].UID)
      .set({ UID: this.allUsers[this.userTeamIndex].UID });

    alert('Added user to Team!');
  }

  releaseUserAD() {
    this.db
      .collection('/Users')
      .doc(this.allUsers[this.userReleaseIndex].UID)
      .snapshotChanges()
      .subscribe(user => {
        this.unlockUser = user.payload.data() as Register;

        this.db
          .collection('/Ad-Submissions')
          .doc(this.unlockUser.currentSubmissionID)
          .update({ isSubmitted: false });
      });
    alert("Released user's Ad Submission!");
  }

  goToAdSubmission() {
    this.router.navigateByUrl('/submitAD');
  }

  updateListing(listing: Listing) {
    this.db
      .collection('Listings')
      .doc(listing.ID)
      .update({ ...listing });
  }

  releaseAllUserADs() {
    this.db
      .collection('/Ad-Submissions')
      .snapshotChanges()
      .subscribe(AdSubmissions => {
        AdSubmissions.forEach(AdSubmission => {
          let tmpSubmission = AdSubmission.payload.doc.data() as Ad_Submission;
          let ID = tmpSubmission.AdSubmissionID;
          this.db
            .collection('/Ad-Submissions')
            .doc(ID)
            .update({ isSubmitted: false });

          this.db
            .collection('/Listings')
            .snapshotChanges()
            .subscribe(allListings => {
              allListings.forEach(listing => {
                var list = listing.payload.doc.data() as Listing;
                list.PhotoOption = 'Repeat from last issue';
                this.updateListing(list);
              });
            });
        });
      });
    alert('Released all users Ad Submissions!');
  }
}
