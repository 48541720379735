import * as firebase from 'firebase';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Register } from './register.service';
import { Timestamp } from '@firebase/firestore-types';
import { AngularFireStorage } from '@angular/fire/storage';

import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';

export class Listing {
  userID: string;
  ID: string;
  Address: string;
  Price: string;
  NeighnourhoodNum: number;
  City: string;
  Bedrooms: number;
  Bathrooms: number;
  //Postal: string;
  Province: string;
  Description: string;
  SpecialInstructions: string;
  Banner: string;
  CustomBanner: string;
  PhotoOption: string;
  numOfPhoto: number;
  MLS: string;
  MLSNum: string;
  //PictureCollectionID: string;
  //thumbnail: string;
  isActive: boolean;
  itemOrder: number;
  DateCreated: Timestamp;
  UpdateDay: Timestamp;
  assets: string[];
  includeImg: boolean[];
  hasAR: boolean;
  VTLink: string;
}

@Injectable({
  providedIn: 'root',
})
export class ListingService {
  private propertyCollection: AngularFirestoreCollection<Listing>;
  private properties: Observable<Listing[]>;

  private listings: Listing[] = [];
  private listingAssets: string[] = [];
  private newAssets: string[] = [];

  private _listingAssets: string[] = [];

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.propertyCollection = db.collection<Listing>('Listings');

    this.properties = this.propertyCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getProperties() {
    return this.properties;
  }

  getProperty(id) {
    return this.propertyCollection.doc<Listing>(id).valueChanges();
  }

  updateProperty(property: Listing, id: string) {
    return this.propertyCollection.doc(id).update(property);
  }

  addProperty(
    property: Listing,
    user: Register,
    listingsLength: number
  ): string {
    let id = this.db.createId();
    property.ID = id;
    property.userID = user.UID;
    property.itemOrder = listingsLength++;
    property.DateCreated = firebase.firestore.FieldValue.serverTimestamp() as Timestamp;
    property.UpdateDay = firebase.firestore.FieldValue.serverTimestamp() as Timestamp;
    property.includeImg = [];
    this.db
      .collection('/Listings')
      .doc(id)
      .set({ ...property });

    this.db
      .collection('/Listings')
      .doc(id)
      .collection('/UserIDs')
      .doc(user.UID)
      .set({ user: user.UID });

    //if (user.teamID.length > 0) {
    //  this.db
    //    .collection('/Teams')
    //    .doc(user.teamID)
    //    .collection('/Team-Members')
    //    .snapshotChanges()
    //    .subscribe(teamMembers => {
    //      teamMembers.forEach(teamMember => {
    //        this.db
    //          .collection('/Listings')
    //          .doc(id)
    //          .collection('/UserIDs')
    //          .add(teamMember.payload.doc.data() as Register);
    //      });
    //    });
    //}

    return id;
  }

  updateOrders(allListings: Listing[]) {
    for (var i = 0; i < allListings.length; i++) {
      this.db
        .collection('/Listings')
        .doc(allListings[i].ID)
        .update({ itemOrder: i });
    }
  }

  getAdListings(uid: string) {
    this.db
      .collection('/Listings', (ref) =>
        ref
          .where('userID', '==', uid)
          .where('isActive', '==', true)
          .orderBy('itemOrder')
      )
      .snapshotChanges()
      .subscribe((val) => {
        this.listings.length = 0;
        val.forEach((listing) => {
          this.listings.push(listing.payload.doc.data() as Listing);
        });
      });
    this.db
      .collection('/Listings', (ref) =>
        ref
          .where('userID', '==', uid)
          .where('isActive', '==', true)
          .orderBy('itemOrder')
      )
      .snapshotChanges()
      .subscribe();
  }

  get adListings() {
    return of(this.listings);
  }

  updateListing(listing: Listing) {
    listing.UpdateDay = firebase.firestore.FieldValue.serverTimestamp() as Timestamp;
    this.db.collection('/Listings').doc(listing.ID).update(listing);
  }

  updateSubmissionListings(updates: Listing[]) {
    for (let i = 0; i < updates.length; i++) {
      updates[i].itemOrder = i;
    }

    updates.forEach((listing) => {
      let listingID = listing.ID;
      listing.UpdateDay = firebase.firestore.FieldValue.serverTimestamp() as Timestamp;
      this.db.collection('/Listings').doc(listingID).set(listing);
    });
  }

  deactivateListing(listingID: string) {
    this.db
      .collection('/Listings', (ref) => ref.where('ID', '==', listingID))
      .snapshotChanges()
      .subscribe((listing) => {
        if (Object.values(listing[0].payload.doc.data()).length <= 7) {
          this.db.collection('/Listings').doc(listingID).delete();
        } else {
          this.db
            .collection('/Listings')
            .doc(listingID)
            .update({ isActive: false });
        }
      });
  }

  updateThumbnailPic(
    listingID: string,
    downloadURL: string,
    currentThumbnail: string
  ) {
    if (currentThumbnail != undefined) {
      this.removeThumbnailPic(currentThumbnail);
    }

    //Update the download link to the new thumbnail download link
    this.db
      .collection('/Listings')
      .doc(listingID)
      .update({ thumbnail: downloadURL });
  }

  removeThumbnailPic(currentThumbnail: string) {
    return this.storage.storage.refFromURL(currentThumbnail).delete();
  }

  removeProperty(id) {
    return this.propertyCollection.doc(id).delete();
  }

  get assets() {
    return of(this.listingAssets);
  }

  /* 
    Upload images from the multi image dropzone
  */
  multiImgUpload(files: File[], assets: string[]) {
    this.listingAssets = assets;

    files.forEach((file) => {
      const path = `listing/${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);

      this.storage.upload(path, file).then(() => {
        ref.getDownloadURL().subscribe((downloadURL) => {
          this.listingAssets.push(downloadURL);
          this.newAssets.push(downloadURL);
        });
      });
    });
  }

  deletePhoto(photo: string) {
    //Delete from storage
    return this.storage.storage.refFromURL(photo).delete();
  }

  updateListingDB(listingID: string, downloadURL: string) {
    let subscription = this.db
      .collection('/Listings')
      .doc(listingID)
      .snapshotChanges()
      .subscribe((listing) => {
        this._listingAssets = [];
        var tmpListing = listing.payload.data() as Listing;
        this._listingAssets = tmpListing.assets;
        this._listingAssets.push(downloadURL);

        this.db
          .collection('/Listings')
          .doc(listingID)
          .update({ assets: this._listingAssets });
        subscription.unsubscribe();
      });
  }

  get getListingAssets() {
    return of(this._listingAssets);
  }

  // Set photo option to 'use from last issue' upon release of all ads
  photoOptionDefault() {
    // get all documents from /Listings that are currently active
    this.db
      .collection('/Listings', (ref) => ref.where('isActive', '==', true))
      .get()
      .subscribe((listings) => {
        var updated = 0;
        var error = 0;
        var total = 0;
        // iterate through all active listings and change the PhotoOption
        listings.forEach((listing) => {
          let ref = this.db.collection('/Listings').doc(listing.id);
          ref
            .update({
              PhotoOption: 'Repeat from last issue',
            })
            .then(() => {
              updated++;
              total++;
              console.log(`${updated}/${listings.size}`);
              // alert when all listings are updated
              if (listings.size === total)
                alert(`Done. ${updated}/${listings.size} updated.`);
            })
            .catch(() => {
              error++;
              total++;
            });
        });
      });
  }
}
