import { Observable, Subject } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ListingService } from '../services/listings.service';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';

@Component({
  selector: 'app-img-upload',
  templateUrl: './img-upload.component.html',
  styleUrls: ['./img-upload.component.scss'],
})
export class ImgUploadComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  @Input() ID: string;
  @Input() directory: String;

  isHovering: boolean;
  downloadURL: string;

  allFiles: File[] = [];
  fileCount: number;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private listingService: ListingService
  ) {}

  ngOnInit() {}

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: File[]) {
    this.allFiles = files;
    this.fileCount = files.length;

    for (var i = 0; i < this.allFiles.length; i++) {
      if (this.allFiles[i].type.split('/')[0] !== 'image') {
        console.error('Unsupported file type :( ');
        alert('Please only upload JPEG files!');
        return;
      }
    }

    for (var i = 0; i < files.length; i++) {
      const path = `${this.directory}/${Date.now()}_${files[i].name}`;
      const ref = this.storage.ref(path);

      this.task = this.storage.upload(path, files[i]); // upload image to Firebase storage in listings folder
      this.percentage = this.task.percentageChanges();
      this.snapshot = this.task.snapshotChanges();

      switch (this.directory) {
        case 'listing':
          //Listing Assets
          this.listingImage(ref);
          break;
        case 'cardBack':
          //Profile Card Background Pic
          break;
        case 'image':
          //Profile Pic
          break;
      }
    }
  }

  listingImage(ref) {
    this.task.then(() => {
      ref.getDownloadURL().subscribe((downloadURL) => {
        // get download URL by subscribing to getDownloadURL observable
        this.downloadURL = downloadURL;
        this.listingService.updateListingDB(this.ID, this.downloadURL); // call updateListingDB method in listingService to update the assets array of the Listing with the image download URL
        // Removed the following section and put in updateListingDB method in listingService
        // this.listingService.getListingAssets.subscribe(assets => {
        //   this.db
        //     .collection('/Listings')
        //     .doc(this.ID)
        //     .update({ assets: assets });
        // });
      });
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
